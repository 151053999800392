.SAODTP {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  font-size: 14px;
  padding: 15px;
  border: 1px solid #97989a;
}

.SAODTP:focus {
  outline: none;
  border: 2px solid #20a8ec;
}

.SAOD-autocomplete-parent .SAOD-autocomplete {
  width: 75%;
  border-radius: 51px;
  background-color: white;
}

.SOAAD-modal-container {
  height: 300px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
/* Center the modal in the middle of the screen */
.centered-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

/* Style the modal content */
.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 50vw; /* Adjust the width as needed */
  position: relative;
}

/* Style the submit button */
.SAOD-apply-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

/* Hide the modal scrollbar */
.modal-content::-webkit-scrollbar {
  width: 0;
}

/* Handle the scrollbar on Firefox */
.modal-content {
  scrollbar-width: none;
}

/* Add padding to the bottom of the modal content for the alert */
.modal-content .MuiAlert-root {
  margin-top: 20px;
}

.SAOD-button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.css-12n0uxm-MuiGrid-root .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 40px;
  height: 50px;
}
@media screen and (max-width: 600px) {
  .modal-content {
    max-width: 80vw;
  }
}
