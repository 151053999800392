
.car-details-otp-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 450px;
    /* height: 350px; */
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    gap: 20px;
    position: relative;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
    border-radius: 15px;
  }
  
  .car-details-otp-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 337px;
    width: 588px;
    margin-top: 22px;
    margin-left: 26px;
    box-shadow: inset 2px 4px 4px 4px rgba(0, 0, 0, 0.02);
    background: #f5fbff;
    border-radius: 20px;
  }
  
  .car-details-otp-box-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .car-details-otp-box {
    background-color: rgb(228, 228, 228);
    width: 30px;
    height: 30px;
    text-align: center;
    border: none;
    border-radius: 7px;
    caret-color: rgb(127, 129, 255);
    color: rgb(44, 44, 44);
    outline: none;
    font-weight: 600;
  }
  
  .car-details-otp-submit-button-parent {
    margin-top: 20px;
  }
  
  .car-details-otp-submit-button {
    padding: 5px 10px;
    border: none;
    background-color: rgb(35 168 250);
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
    transition-duration: .2s;
  }
  .car-details-otp-submit-button:hover {
    background-color: rgb(27 141 211);
    transition-duration: .2s;
  }

  
  
  
  
 
  .resendNote {
    font-size: 0.9em;
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .resendBtn {
    background-color: transparent;
    border: none;
    color: rgb(35 168 250);
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 700;
  }
  /* OTP MODAL CSS START  */
  
  .mainHeading {
    font-size: 1.1em;
    color: rgb(15, 15, 15);
    font-weight: 700;
  }
  
  .otpSubheading {
    font-size: 0.9em;
    color: black;
    line-height: 17px;
    text-align: center;
  }
  
  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
  
  /* ....................................................@media screen {}.....................  */
  @media screen and (max-width:885px){
    .vehical_detail_container{
      grid-template-columns: repeat(auto-fit, minmax(219px ,1fr));
    }
    .custom-radio-button{
      width: 197px;
    }
    .previous_insurer_radio_button {
      width: 215px;
    }
    .previous_insurer_custom_radio_button {
      width: 96px;
    }
    .vehical_detail_main_container {
      width: 98%;
    }
    
  }
  
  
  
  @media screen and (max-width:835px){
    
    .custom-radio-button{
      width: 170px;
    }
  
    .vehical_detail_container {
      grid-template-columns: repeat(auto-fit, minmax(188px ,1fr));
    }
  
    .vehical_detail_model_search_box {
      grid-column: 3 / 4;
  }
  .previous_policy_type {
    grid-column: 1/3;
  }
  .custom-radio-button {
    width: 225px;
  }
  .previous_insurer_radio_button{
    width: 200px;
  }
  .previous_insurer_custom_radio_button {
    width: 95px;
  }
  .vehical_detail_content_labels {
    font-size: 12px;
  }
  }
  
  @media screen and (max-width:753px){
    .vehical_detail_model_search_box {
      grid-column: 1 / 3;
  }
  .previous_policy_type {
    grid-column: 1/3;
  }
  .custom-radio-button {
    width: 194px;
  }
  .car-details-otp-modal{
    width: 89vw;
  }
  }
  
  @media screen and (max-width:476px){
    .vehical_detail_content_labels{
  
      font-size: 12px;
    }
    .previous_insurer_radio_button{
      width: 200px;
    }
    .previous_insurer_custom_radio_button{
      width: 90px;
    }
    .vehical_detail_model_search_box {
      grid-column: 1 / 3;
  }
  .previous_policy_type {
    grid-column: 1/3;
  }
  .custom-radio-button {
    width: 192px;
  }
  }
  
  
  @media screen and (max-width:438px){
    .vehical_detail_main_container {
      width: 92%;
    }
    .vehical_detail_model_search_box{
      grid-column: 1 / 2;
    }
    .registration_type {
      grid-column: auto;
    }
    .previous_policy_type {
      grid-column: 1/2;
    }
    .custom-radio-button{
      width: 150px;
    }
  
    .vehical_detail_container {
      grid-template-columns: repeat(auto-fit, minmax(190px ,1fr));
    }
  
    .row_buttons {
      width: 55%;
    }
    .custom-radio-small-button{
      width: 88px;
    }
    
  }
  
  @media screen and (max-width:350px){
    .custom-radio-button {
      width: 140px;
  }
  .row_buttons {
    width: 74%;
  }
  }
  @media screen and (max-width:320px){
    .custom-radio-button {
      width: 131px;
  }
  .row_buttons {
    width: 200px;
  }
  }