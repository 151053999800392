

.registration-number-top-section-enter-registration-box {
    width: 495px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    /* border: 2px solid red;    */
}

.shield-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-46%, -53%);
    width: 75%;
    height: 100%;
}

.enter-registration-inner-box {
    width: 100%;
    height: 250px;
    border: 1px solid #bbb;
    /* border: 1px solid rgb(0, 4, 255); */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background-color: #fff;
    z-index: 1;
}

.enter-registration-inner-box-center {
    position: relative;
    width: 100%;
    margin: 40px 20px;
    /* border: 2px solid black; */
}

.enter-registration-inner-box-center input {
    width: 100%;
    height: 55px;
    border-radius: 14.667px;
    border: 0.917px solid rgba(0, 0, 0, 0.10);
    background: #FEFEFE;
    box-shadow: 0px 0px 3.6666667461395264px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    color: BLACK ;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 16px;
}
.enter-registration-inner-box-center input::placeholder{
    color: #bbb;
}
.validate-warning {
    display: inline;
    position: absolute;
    top: 30%;
    left: 3%;
    right: 0;
    bottom: 0;
    height: 30px;
}

.registration-number-top-button {
    position: relative;
    width: 100%;
    height: 73px;
    display: flex;
    align-items: center;
    border-radius: 14.667px;
    background: #FEFEFE;
    margin-top: 29px ;
    cursor: pointer;
}

.registration-number-top-button-innericons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
    padding: 0 8px;
}

.registration-number-top-button button {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #E4F5FF;
    box-shadow: 0px 0px 5.5px 1.8333333730697632px rgba(35, 168, 250, 0.37);
    border: none;
    outline: none;
    color: #23A8FA;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.new-vehical-registration-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.new-vehical-registration-box span {
    border-radius: 8.667px;
    padding: 3px 7px;
    border: 0.917px solid rgba(0, 0, 0, 0.10);
    background: #FEFEFE;
    cursor: pointer;
    color: #23A8FA;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media screen and (max-width:480px) {
    .registration-number-top-section-enter-registration-box {
        width: 348px;
    }

    .enter-registration-inner-box {
        width: 100%;
        /* height: 237px; */
    }

    .enter-registration-inner-box-center {
        /* width: 100%; */
        /* border: 2px solid black; */
    }
    
    .validate-warning{
        font-size: 12px;
        top: 28%;
        
    }
    .new-vehical-registration-box {
        flex-direction: column-reverse;
        text-align: center;
        height: 60px;
    }

    .registration-number-top-button {
        margin-top: 20px;
        height: 50px;
    }

    .registration-number-top-button button {
        font-size: 16px;
    }
    .registration-number-top-button-innericons > img {
        width: 40px;
    }
}

@media screen and (max-width:320px) {
    .validate-warning{
        font-size: 10px;
        top: 28%;
        
    }
    .registration-number-top-section-enter-registration-box {
        width: 270px;
    }
    .shield-img {
        transform: translate(-46%, -53%);
        width: 100%;
        height: 100%;
    }

    .enter-registration-inner-box {
        width: 100%;
        height: 255px;
    }

    .enter-registration-inner-box-center {
        margin: 0px 13px;
    }

    .new-vehical-registration-box {
        flex-direction: column-reverse;
        text-align: center;
        height: 60px;
    }

    .registration-number-top-button {
        margin-top: 20px;
        height: 50px;
    }

    .registration-number-top-button button {
        font-size: 13px;
    }
    .registration-number-top-button-innericons > img {
        width: 30px;
    }
}