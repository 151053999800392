.proposer_details_heading {
    margin-bottom: 1.2rem;
}
.proposer_details_heading h6{
    color: #333;
    font-weight: 700!important;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}


.proposer_details_form_1 .inputGroup{
    margin: 0px 0px 25px 0px;
}


.error {
  color: red;
  font-size: 0.85rem;
  margin-top: 4px;
}

 .proposer_form_inner {
    /* display: flex; */
    /* border: 2px solid rgb(10, 10, 66); */
}

 .proposer_form_inner .proposer_details_form_right {
    width: calc(100% - 320px);
}


 .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_company_img{
    width: 120px;
    height: 60px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
}
 .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_company_img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
 .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_plans_details li{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
}
 .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_plans_details li b{
    font-size: 16px;
    color: #3c3c3c;
    font-weight: 700;
}
 .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_plans_details li span{
    font-size: 16px;
    color: #989898;
    font-weight: 400;
}
.health_btn {
    align-items: center;
    background: #23a8fa;
    border: none;
    border-radius: 0.9em;
    box-shadow: inset 0 0 1.6em -0.6em #2084c2;
    color: #fff;
    display: inline-flex;
    font-family: inherit;
    font-size: 17px;
    font-weight: 500;
    height: 2.8em;
    letter-spacing: .05em;
    overflow: hidden;
    padding: 0.35em 3.3em 0.35em 1.2em;
    position: relative;
}


.proposer_details_form_right .form_button{
    justify-content: space-between;
}


/* Extra small devices (phones) */
@media (max-width: 575.98px) {
    /* CSS for extra small devices */

     .proposer_form_inner .proposer_details_form_right {
        width: calc(100% - 0px);
    }
     .proposer_form_inner {
        flex-direction: column;
    }
     .proposer_form_inner .policy_details_side_card {
        width: 100%;
        height: unset;
    }
     .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_company_img {
        margin-bottom: 15px;
        width: 80px;
        height: 50px;
    }
     .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_plans_details li b{
        font-size: 14px;
    }
     .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_plans_details li span{
        font-size: 12px;
    }
     .proposer_form_inner .policy_details_side_card .proposer_price_details ul{
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
     .proposer_form_inner .policy_details_side_card .proposer_price_details .policy_plans_details li {
        margin-bottom: 0px;
    }
     .proposer_form_inner .policy_details_side_card .proposer_price_details ul li:nth-child(3){
        display: none;
    }
  }