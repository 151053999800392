.payment-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 21px;
  padding: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  width: 950px;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-status-logo-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 15px;
}

.payment-status-title {
  text-align: center;
  width: 345px;
  height: 28px;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  color: #121212;
  align-self: center;
  margin-top: 4px;
  margin-bottom: 20px;
}

.payment-success-table {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 570px;
  height: 250px;
  left: 72px;
  top: 392.82px;
  background: #fdfeff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  margin-top: 15px;
}

.payment-success-table-header {
  display: flex;
  justify-content: space-between;
}

.payment-success-table-divider {
  position: relative;
  width: 99%;
  border-top: 1px solid #dcdee0;
  margin-top: 10px;
  /* height: 0px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 5px;
  margin-bottom: 20px; */
}

.payment-success-table-body {
  display: flex;
  justify-content: space-between;
}

.payment-success-table-body-title {
  margin-top: 5px;
  flex-direction: column;
}

.payment-success-table-body-status {
  margin-top: 5px;
}

.payment-success-view-policy {
  margin-top: 12px;
  font-family: "Arial";
  font-weight: 400;
  font-size: 17px;
}

.payment-success-support {
  align-items: center;
  margin-top: 10px;
  padding: 10.7239px 9.65147px;
  gap: 6.43px;
  width: 200px;
  height: 47.18px;
  background: #ffffff;
  box-shadow: 0px 4.28954px 12.8686px rgba(170, 170, 170, 0.12);
  border-radius: 12.8686px;
}

.payment-success-button-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px 24px;
  display: flex;
}

.payment-success-table-container {
  display: flex;
  /* width: 98%; */
  align-items: center;
}

.kyc-failed-container {
  position: relative;
  margin-left: 20px;
  width: 570px;
  height: 93%;
  background: #fdfeff;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
  margin-top: 15px;
  padding: 18px 0;
  align-items: center;
}
.icons-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 80px;
  top: 5%;
  left: 30%;
}

.kyc-failed-content {
  margin-left: 37px;
  margin-top: 38px;
}

.kyc-failed-content-title {
  width: 210px;
  height: 45.17px;
  /* font-family: "Poppins"; */
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #23a8fa;
}

.kyc-failed-content .kyc-failed-btn {
  width: 100%;
  border-radius: 30px;
  background-color: #23a8fa;
}

.kyc-failed-btn .kyc-failed-btn-text {
  width: 224px;
  height: 28px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20.5599px;
  color: #ffffff;
}
.payment-success-table-body-item {
  margin-bottom: 7px;
  text-align: right;
}

.application-id {
  word-wrap: break-word;
  word-break: break-all;
  max-width: 400px;
  color: #121212;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}
.payment-success-table {
  width: 70%;
  height: 93%;
}
.payment-success-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.payment-success-list li {
  margin-bottom: 5px;
  padding-top: 5px; /* Adjust padding as needed */
  display: flex;
}

.payment-success-list li span:first-child {
  flex-grow: 1;
  margin-right: 20px; /* Adjust margin as needed */
}

.payment-success-list span:not(:first-child) {
  text-align: right;
}

.payment-success-list li span {
  display: inline-block;
  width: calc(50% - 10px); /* Adjust width and margin as needed */
  margin-right: 20px; /* Adjust margin as needed */
}

@media screen and (max-width: 768px) {
  .payment-success-table-container {
    flex-direction: column;
  }
  .payment-container {
    width: 100%;
  }
  .kyc-failed-container {
    width: 100%;
  }
  .payment-success-table {
    width: 95%;
  }
  .kyc-failed-container {
    margin-left: 0;
  }
}
