::-webkit-scrollbar {
  /* display: none; */
}

a,
a:hover {
  text-decoration: none;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.personal_accident_page {
  height: 100%;
  padding: 30px 0;
}

.personal_accident_page .quotation_main_container {
  height: 90vh;
  overflow: hidden;
}

.personal_accident_page .quotation_main_container .quotation_main_scroll {
  height: 100vh;
  padding: 5px 10px;
  overflow-y: scroll;
}

.quotation_card {
  background-color: #fff;
  box-shadow: 0 0 10px 0 #0d398f26;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 23px;
}

.personal_accident_page .quotation_card .img_media {
  width: 158px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 15px;
}

.personal_accident_page .quotation_card .img_media img {
  width: auto;
  max-width: 100%;
}

.personal_accident_page .quotation_card .content_media {
  width: calc(100% - 130px);
  padding-left: 15px;
  padding-right: 5px;
}

.content_box_cs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personal_accident_page
  .quotation_card
  .content_media
  .content_box_cs
  .insur_add_to.quote_equal_compare {
  display: flex;
  justify-content: center;
}

.hal_buy_now_btn.quote_equal_compare {
  display: flex;
  justify-content: flex-end;
}

.buy_now_button {
  background: #23a8fa;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 9px;
  cursor: pointer;
  width: 140px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ddd;
}

.buy_now_button {
  font-size: 15px !important;
}

.buy_now_button small {
  font-size: 13px;
  margin-bottom: 1px;
  display: block;
  text-align: center;
  line-height: 15px;
}

.buy_now_button span {
  display: block;
  text-align: center;
  line-height: 20px;
}

.buy_now_button p {
  font-size: 10px;
  margin: 0;
  font-weight: 400;
  line-height: 15px;
}

.title_pa {
  font-size: 15px;
  font-weight: 600;
  color: #23a8fa;
}

.d_flex {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.plans_container {
  justify-content: space-between;
}

.personal_accident_page .quotation_card .li_plans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-template-rows: auto auto;
  padding: 10px;
  width: 50%;
}

.personal_accident_page .quotation_card .li_plans li {
  font-size: 0.875em;
  font-weight: 400;
  color: #373737;
  line-height: 24px;
  padding: 0 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.personal_accident_page .quotation_card .li_plans b {
  font-size: 0.75rem;
}

.personal_accident_page .quotation_card .li_plans small {
  font-size: 0.75rem;
}

.personal_accident_page .quotation_card .remark_box {
  font-size: 13px;
  font-weight: 500;
  color: #3c4346;
  background: #23a8fa5c;
  border-radius: 15px;
  padding: 4px 10px;
  letter-spacing: 1px;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}




/* quotation page end */



/* card starts */

.d_flex {
  display: flex;
}

.quotation_main_card {
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: relative;
  border-radius: 12px;
  padding: 5px;
  overflow: hidden;
}

.quotation_main_card .logo_container {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  flex-direction: column;
  border-right: 1px dotted #bbb;
}

img {
  width: auto;
  max-width: 100%;
}

.quotation_main_card .quotation_content {
  width: calc(100% - 120px);
  padding-left: 15px;
  padding-right: 5px;
}

.quotation_content .content_box_cs .plan_details {
  width: calc(100% / 3);
}
.quotation_content .content_box_cs .plan_details:nth-child(2) {
  width: calc(100% / 1);
  justify-content: flex-start !important;
}
.quotation_content .title_pa {
  font-size: 15px;
  font-weight: 600;
  color: #10498a;
}
.quotation_content .suminsured_text {
  color: #03a9f4;
}

.quotation_main_card .quotation_content .suminsured_text {
  font-size: 14px;
  font-weight: 700;
  margin-top: 0px;
}

.quotation_main_card
  .quotation_content
  .content_box_cs
  .hal_buy_btn_btn.plan_details {
  display: flex;
  justify-content: flex-end;
}

.quotation_main_card .buy_btn {
  background: #03a9f4;
  color: white;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 140px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ddd;
}

.quotation_main_card .buy_btn span {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 20px;
}

.buy_btn p {
  font-size: 10px;
  margin: 0;
  font-weight: 400;
  line-height: 15px;
}

.quotation_main_card
  .quotation_content
  .content_box_cs
  .plan_feature.plan_details {
  display: flex;
  justify-content: space-between;
}

.quotation_main_card .quotation_bottom_div .features_btn {
  border: none;
  width: 100%;
  text-align: flex-start;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px;
}

.font-12 {
  font-size: 12px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .quotation_main_card .plan_features .features_list li {
    width: calc(100% / 2 - 0px);
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .quotation_main_card
    .quotation_content
    .content_box_cs
    .plan_feature.plan_details {
    justify-content: flex-end;
    position: absolute;
    bottom: 6px;
  }
}

@media (max-width: 768px) {
  .d_flex.border_bottom.pb_10.quotation_upr_div {
    padding-bottom: 0px !important;
  }
  .quotation_main_card {
    padding: 10px;
  }
  .quotation_main_card .quotation_upr_div {
    flex-direction: column;
    position: relative;
  }

  .quotation_main_card .logo_container {
    width: 90px;
    height: 40px;
    padding: 2px;
  }

  .quotation_main_card .quotation_content {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }

  .quotation_main_card .buy_btn {
    font-size: 12px;
    width: 120px;
    height: 50px;
  }
  .quotation_main_card .quotation_content .suminsured_text {
    font-weight: 500;
    font-size: 12px;
  }

  .quotation_main_card .quotation_upr_div .hal_buy_btn_btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .buy_btn small {
    font-size: 10px;
    margin-bottom: 1px;
  }

  .quotation_main_card
    .quotation_content
    .content_box_cs
    .plan_feature.plan_details {
    display: none;
  }
}

@media (max-width: 576px) {
  .quotation_main_card .quotation_content .content_box_cs {
    flex-wrap: wrap;
  }

  .quotation_main_card .quotation_content .content_box_cs .plan_details {
    width: calc(100% / 1);
    line-height: 22px;
  }

  .buy_btn span {
    line-height: 15px;
  }

  .quotation_main_card .quotation_content .content_box_cs .plan_details {
    width: calc(100% / 1);
    line-height: 22px;
  }
}
