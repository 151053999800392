
.inner-div {
  background: #ffffff;
    margin: 0 0 12px;
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: center;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 8.6px;
}

.alignCenter {
  align-items: center;
}

.view-details-logo {
  width: 100px;
  height: 75px;
  border: 1px solid #dfe1e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.logo-details {
  margin-right: 12px;
}
.logo-details img {
  width: 80px;
  height: auto;
  object-fit: contain;
}
.plan-name h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
.inner-div.second {
  width: 100%;
  box-shadow: 0 6px 16px rgba(37,56,88,.15);
  border-radius: 16px;
  background-color: #fff;
  height: auto;
  padding: 8px 0 0;
  position: sticky;
  position: -webkit-sticky;
  top: 74px;
}

.right_product{
  position: relative;
  z-index: 1;
}

.right_product .right_summery_section {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 9.6px;
  border-radius: 16px;
  background-color: #fff;
  height: auto;
  padding: 8px 0 0;
  position: sticky;
  position: -webkit-sticky;
  top: 80px;
}

.right_product .right_summery_section h3 {
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #b3bac5;
  padding-bottom: 7px;
  padding-left: 16px;
}

.scroll_space {
  padding: 0 16px;
}
.flexRow {
  display: flex;
  flex-direction: column;
}
.right_product .right_summery_section .section_right {
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 0 0;
}
.rider_head {
  margin-top: 8px;
}
.right_product .right_summery_section .section_right span {
  font-weight: 700;
}
.rider_head h4 {
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
}

.select_benefits {
  border: 1px dashed #b3bac5;
  /* height: 38px; */
  align-items: center;
  padding: 0 16px!important;
  border-radius: 4px;
  justify-content: space-between;
  font-size: 14px;
  color: #505f79;
  margin-top: 6px;
}

.select_benefits .link_select {
  font-size: 12px;
  color: #36b37e;
  font-weight: 700;
  cursor: pointer;
}

.premium_right {
  background: #fff;
  border-radius: 0 0 16px 16px;
  margin-top: 0;
  padding: 16px;
}

.premium_right .section_premium {
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  background: #f4f5f7;
  margin-left: -16px;
  margin-right: -16px;
  padding: 8px 16px;
  border-bottom: 1px solid #dfe1e6;
}
.premium_right button {
  width: 100%;
  margin: 16px 0 0;
}

.primaryMainCta {
  -webkit-appearance: none;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  /* transition: background .5s ease-in-out; */
  position: relative;
  height: 48px;
  background: #23a8fa;
  color: #fff!important;
  font-size: 16px;
  border: none;
}
.emi {
  visibility: hidden;
  margin: 0;
  height: 0;
}

.price-effective-wrapper {
  font-size: 14px;
  font-weight: 500;
  background: #e3fcef;
  text-align: center;
}

.price-effective-wrapper {
  padding: 8px 0;
  margin: 0 -16px;
}

.price-effective-wrapper .linkText {
  display: inline-block;
  padding: 0 5px;
  color: #36b37e!important;
}

/* add_ons section css starts here ..................................... */

.heading_section h3 {
  font-size: 18px;
  font-weight: 700;
  padding-right: 20px;
}

.heading_section h3 span {
  font-size: 14px;
  display: block;
  font-weight: 400;
  color: #505f79;
  margin-top: 4px;
}
.heading_section{
  width: 100%;
}

.multi-addons-wrapper{
  border: 1px solid #dfe1e6;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    margin-top: 30px;
    padding-top: 30px;
}

.multi-addons-wrapper .addons_wrapper{
  margin-top: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dfe1e6;
    padding-left: 0;
    padding-right: 0;
}
.addons_wrapper {
  border: 1px solid #dfe1e6;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addons_wrapper .addons_details{
  max-width: 60%;
  padding-right: 16px;
}
.addons_wrapper .addons_type_wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;

}

.addons_wrapper .addons_details h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.addons_wrapper .addons_details .addons-info {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #505f79;
}
.addons_wrapper .addons_type_wrapper  .addons_inner_box {
  min-width: 100px;
  flex-shrink: 0;
  margin-right: 16px;
  margin-left: 16px;
}
.addons_wrapper .addons_type_wrapper  .addons_inner_box p {
  font-size: 16px;
  font-weight: 700;
  color: #253858;
}

.addons_wrapper .addons_type_wrapper  .addons_inner_box p   span {
  display: block;
  font-size: 12px;
  color: #505f79;
  font-weight: 400;
}

.addons_add_buttons button {
  width: 78px;
    text-transform: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    
      -webkit-appearance: none;
      font-weight: 700;
      border-radius: 8px;
      cursor: pointer;
      position: relative;
      height: 48px;
      background: #ff5630;
      color: #fff!important;
      font-size: 16px;
      background: #fff;
      color: #ff5630!important;
      border: 1px solid #ff5630;
      height: 36px;
      font-size: 14px;
  
}

.addons_add_buttons button:before {
  content: "+";
  display: inline-block;
  padding-right: 2px;
}

.addons_add_buttons .input_group .form_control{
  width: 140px;
  height: 40px;
}
.addons_add_buttons .input_group {
  margin-bottom: 0px !important;
}

.addons-importance {
  background-color: #23a8fa;
  position: absolute;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 8px 8px 0 0;
  padding: 10px 12px;
  color: #f4f5f7;
}

.addon-row{
  width: 100%;
  display: flex;
  justify-content: space-between;
}