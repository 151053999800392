.modal-container .make-modal-varient-main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 641px;
  height: 408px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  margin-top: -30px;
  border-radius: 20px !important;
}
.make-modal-varient-tab-parent {
  margin-bottom: 10px;
}
.make-modal-varient-tab-parent .make-modal-varient-tab-label {
  font-size: 14px;
  font-weight: 600;
}
.make-modal-varient-main-container .make-modal-varient-suggestion-div {
  height: 260px;
  overflow: auto;
}

.make-modal-varient-eachelement {
  border: 1px solid #97989a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 15px;
  background-color: white;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}
.maker-model-suggestion-div-parent {
  margin-left: 40px;
}
.make-modal-varient-eachelement:hover {
  background-color: #31a0d8;
  color: white;
  transition: 0.5s;
  font-weight: 600;
  scale: 1.15;
}

.make-modal-varient-apply-button {
  background-color: #31a0d8;
  text-align: center;
  color: white;
  padding: 5px;
  width: 80px;
  border: none;
  margin: 0px auto;
  border-radius: 10px;
}
.address-search-box {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease-in-out;
}

.address-search-box::placeholder {
  color: #9e9ea7;
}

.srach-Box-parent-div {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 245px;
  margin: 12px auto;
}
.srach-Box-parent-div .icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

.address-search-box:focus,
.address-search-box:hover {
  outline: none;
  border-color: rgba(234, 76, 137, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.state-city-modal-parent {
  /* margin-left: 40px; */
  padding: 10px 10px;
}

.vehicle-type-dropdown {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.brand-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.vehicle-type-dropdown {
  width: 50%;
  margin: auto;
  margin-bottom: 10px;
}
.cvselect {
  height: 40px;
  border-radius: 25px;
}
.cvlabel {
  text-align: left;
  font-weight: bolder;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: system-ui;
}

.main {
  margin-top: 20px;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
  transition: all 0.3s ease-in-out;
}

.tabs * {
  z-index: 2;
}

.containers input[type='radio'] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 50px;
  font-size: 0.8rem;
  color: black;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 10px;
  left: 30%;
  font-size: 10px;
  margin-left: 0.75rem;
  border-radius: 50%;
  margin: 0px;
  background-color: #e6eef9;
  transition: 0.3s ease-in-out;
}

.containers input[type='radio']:checked + label {
  color: #185ee0;
}

.containers input[type='radio']:checked + label > .notification {
  background-color: #185ee0;
  color: #fff;
  margin: 0px;
}

.containers input[id='radio-1']:checked ~ .glider {
  transform: translateX(0);
}

.containers input[id='radio-2']:checked ~ .glider {
  transform: translateX(100%);
}

.containers input[id='radio-3']:checked ~ .glider {
  transform: translateX(200%);
}

.glider {
  position: absolute;
  display: flex;
  height: 30px;
  width: 50px;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99px;
  transition: 0.2s ease-in-out;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }

  .modal-container .make-modal-varient-main-container {
    width: 97%;
    padding: 30px 10px;
  }
  .make-modal-varient-tab-label {
    font-size: 9px;
  }
  .css-1k1029z-MuiGrid-root .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 70px;
  }
}
