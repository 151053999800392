.blur {
  filter: blur(1.4px);
}

.text_center {
  text-align: center;
}

.male_female_box {
  margin-bottom: 10px;
}

.male_female_box input {
  display: none;
}

.male_female_box label {
  cursor: pointer;
}


.male_female_box label span.gender_text {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 15px;
  display: block;
}

/* familiymembers selection radioinput boxes CSS  */
.travel_readio_btn input {
  display: none;
}

.travel_readio_btn label {
  background: transparent;
  text-align: center;
  display: block;
  text-decoration: none;
  padding: 0px 10px;
  transition: all 0.5s ease-out;
  border: none;
  cursor: pointer;
  /* width: calc(100% / 2); */
  width: 100%;
}

.travel_readio_btn input:checked+label .icon_tab {
  background: #03a9f436;
}

.travel_readio_btn input:checked+label .icon_tab {
  border-color: #03a9f4;
  color: #03a9f4;
}

.travel_readio_btn label .icon_tab {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  padding: 0 16px;
  border-radius: 9px;
  /* margin: auto auto 21px; */
  font-size: 40px;
  color: #5e5e60;
  border: 2px solid #d4d4d4;
  position: relative;
}

.travel_readio_btn label .icon_tab img {
  width: 35px;
}

.travel_readio_btn label span {
  display: block;
  position: relative;
  color: #5e5e60;
  font-size: 15px;
  font-weight: 700;
  margin-left: 15px;
  user-select: none;
}

.travel_readio_btn input:checked+label span {
  color: #23a8fa;
}

.travel_readio_btn .counter_cs {
  position: absolute;
  display: flex;
  height: 30px;
  width: 100px;
  bottom: -15px;
  justify-content: space-around;
  align-items: center;
  z-index: 9;
  left: 0px;
  right: 0px;
  background: #fff;
  border: 3px solid #23a8fa;
  border-radius: 17px;
  margin: auto;
}

.travel_readio_btn .counter_cs button.add_member_minus {
  color: #dc3545;
}

.travel_readio_btn .counter_cs button {
  border: none;
  background: transparent;
  font-size: 22px;
  font-weight: 700;
  padding: 0;
}

.travel_readio_btn .counter_cs p {
  margin: 0;
  font-size: 15px;
  font-weight: 800;
  width: 25px;
}

.travel_readio_btn .counter_cs button.add_member_plus {
  color: #198754;
}

.genderLabel {
  color: #03a9f4;
  border-bottom: 2px solid #03a9f4;
}

.genderLabel.active {
  background-color: #03a9f4;
  color: #fff;
}



/* Media Query for Medium Screens (Tablets) */
@media screen and (max-width: 992px) {
  .male_female_box {
    display: block !important;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 15px; 
  }

  .male_female_box .genderLabel {
    width: 90px; 
    height: 40px; 
    font-size: 14px;
  }
}

/* Media Query for Small Screens (Mobile) */
@media screen and (max-width: 576px) {
  .male_female_box {
    flex-direction: row; 
    margin-top: 10px;
    display: block;

    gap: 10px;
  }

  .male_female_box .genderLabel {
    width: 100%; /* Full width for small screens */
    max-width: 120px; /* Optional: Restrict maximum width */
    height: 30px;
    font-size: 16px; /* Larger text for better readability */
  }
}
