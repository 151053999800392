
 .quotation_main_container {
    /* height: calc(100vh - 97px); */
    overflow: auto;
    padding: 20px  15px;
}

 
 .content_box_cs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 

 
.quotation_card .content_media .content_box_cs .insur_add_to.quote_equal_compare {
    display: flex;
    justify-content: center;
}
.hal_buy_now_btn.quote_equal_compare {
    display: flex;
    justify-content: flex-end;
}