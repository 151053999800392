.user_details_form .inputGroup {
  font-family: 'Segoe UI', sans-serif;
  max-width: 100%;
  position: relative;
  margin: unset;
}
.user_details_form .inputGroup .error{
  color: red;
}
.user_details_form .inputGroup .error-message {
color: red;
}


.user_details_form .inputGroup .display_error {
  position: absolute;
  bottom: 1px;
  left: 15px;
  color: red;
  font-size: 14px;
}

.user_details_form .nominee_details_page .input_group {
  margin-top: 25px;
}

.user_details_form .inputGroup input,
.user_details_form .inputGroup select
 {
  font-size: 100%;
  padding: 0.7em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 55px;
  text-transform: capitalize;
}

.user_details_form .inputGroup.focused select{
  border-color: #23a8fa ;
}

.user_details_form .inputGroup label {
  position: absolute;
  top: 1px;
  left: 0;
  padding: 0.8rem;
  margin-left: 0.5rem;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(0, 0, 0);
  transform: translateY(-50%) scale(.9);
  padding: 0.4em;
  background-color: #ffffff;
  font-weight: 400;
  border-radius: unset;
}



.user_details_form .inputGroup input:focus,
.user_details_form .inputGroup input:not(:placeholder-shown) {
  border-color: #23a8fa;
}


.user_details_form .radiogroup{
display: flex;
justify-content: space-between;
}
.user_details_form .radios{
display: flex;
justify-content: space-between;
width: 40%;
}


.user_details_form .radio_label {
font-size: 16px;
pointer-events: none;
transition: all 0.3s ease;
color: rgb(0, 0, 0);
transform: translateY(-50%) scale(.9);
margin-bottom: 9px !important;
}

.user_details_form .radio-inputs {
position: relative;
display: flex;
flex-wrap: wrap;
border-radius: 0.5rem;
background-color: #EEE;
box-sizing: border-box;
box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
padding: 0.25rem;
/* width: 300px; */
font-size: 14px;
margin-top: 8px;
}
.user_details_form .radio-inputs .radio {
flex: 1 1 auto;
text-align: center;
margin: unset;
}
.user_details_form .radio-inputs .radio input {
display: none;
}
.user_details_form .radio-inputs .radio .name {
display: flex;
cursor: pointer;
align-items: center;
justify-content: center;
border-radius: 0.5rem;
border: none;
padding: .5rem 0;
color: rgba(51, 65, 85, 1);
transition: all .15s ease-in-out;
}
.user_details_form .radio-inputs .radio input:checked + .name {
background-color: #fff;
font-weight: 600;
}

.user_details_form .input_info_icon{
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(-50%,-50%);
}