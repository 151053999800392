.date_picker_div {
  position: relative;
  max-width: 100%;
}

.date_picker_div label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.8rem;
  margin-left: 0.5rem;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(0, 0, 0);
  transform: translateY(-50%) scale(.9);
  padding: 0.4em;
  background-color: #ffffff;
  z-index: 1;
}


.date_picker_div .react-datepicker-wrapper input {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 12px;
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border-color: #03a9f4;
  margin-bottom: 25px;
}

.react-datepicker-wrapper {
  display: block;
}

.member_details_heading {
  margin-bottom: 20px;
  font-size: 18px;
}

/* radio buttons css for medical details  */


.radio-button input[type="radio"]:checked+.radio::before {
  opacity: 1;
}


.tabs_contant .proposer_details_form_1 .proposer_button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #23a8fa;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 16px -11px #23a8fa;
  width: 10em;
  padding: 10px 5px;
  transition: all 0.4s;
  cursor: pointer;
}

.tabs_contant .proposer_details_form_1 .proposer_button:hover {
  background-color: #13e660;
  box-shadow: 0px 14px 16px -11px #13e660;
}

.tabs_contant .proposer_details_form_1 .kyc-notify {
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
}

.tabs_contant .proposer_details_form_1 .success-text {
  color: green;
}

.tabs_contant .proposer_details_form_1 .error-text {
  color: red;
}

.tabs_contant .proposer_details_form_1 .spinner_kycstatus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative;
}

.tabs_contant .proposer_details_form_1 .spinner_kycstatus span {
  position: absolute;
  right: 0;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.tabs_contant .input_group {
  margin-bottom: 25px px !important;
  display: block;
  width: 100%;
  position: relative;
}

.tabs_contant .input_group label {
  font-size: 11px;
  color: #3b3b3b;
  font-weight: 600;
  margin-bottom: 0;
  display: block;
  top: -11px;
  position: absolute;
  left: 10px;
  background: #fff;
  padding: 0 5px;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: 1;
}

.tabs_contant .input_group .form_control {
  appearance: none !important;
  height: 55px;
  font-size: 14px;
  width: 100%;
  border: 2px solid #ddd;
  border-color: #23a8fa;
  padding: 0 15px;
  -o-border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  border-radius: 8px !important;
  text-transform: uppercase;
}

.tabs_contant.input_group {
  margin-bottom: 25px !important;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

/* ................................  */

.tabs_contant .inputGroup {
  font-family: "Segoe UI", sans-serif;
  margin: 0px 0 25px 0;
  max-width: 100%;
  position: relative;
}

.tabs_contant .inputGroup input,
.tabs_contant .inputGroup select {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
  height: 55px;
}

.tabs_contant .inputGroup label {
  font-size: 0.9em;
  font-weight: 500;
  position: absolute;
  top: 1px;
  left: 0;
  margin-left: 0.5rem;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(0, 0, 0);
  transform: translateY(-50%) scale(0.9);
  padding: 0.4em;
  background-color: #ffffff;
  border-radius: 12px;
}

.tabs_contant .inputGroup input:focus ,
.tabs_contant .inputGroup input:not(:placeholder-shown) ,
.tabs_contant .inputGroup select:focus ,
.tabs_contant .inputGroup select

{
  border-color: #23a8fa;
}



.tabs_contant {
  height: calc(100vh - 144px);
  overflow-y: auto;
  padding: 0 20px;
}



.tabs_contant::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.tabs_contant::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.tabs_contant::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	
	background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent));
}

.date_picker_div .input_info_icon{
  z-index: 99;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(-50%,-100%);
}
