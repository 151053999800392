/* Add this CSS to your stylesheet */

.testimonial-card {
  background-color: #d2eeff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  max-width: 300px;
  min-height: 240px;
  margin: 12px auto;
}

.testimonial-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.testimonial-name {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin: 10px 0;
}

.testimonial-description {
  font-size: 16px;
  color: #666666;
  margin: 0;
}

.testimonial-section .slick-prev, .testimonial-section .slick-next {
  display: none !important;
}