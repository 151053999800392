.insurance-popup-grid-box .insurance-popup-grid-parent {
  width: 930px;
}

.insurance-popup-grid-parent .insurance-popup-grid {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  width: 300px;
  border-radius: 5px;
  border: 1px solid black;
}
.extra-info {
  display: none;
}

.info:hover ~ .extra-info {
  display: inline-block;
  cursor: pointer;
}

.btn-insurance-modal-parent .btn-insurance-modal {
  width: 50%;
  height: 50%;
  background-color: white;
  border-radius: 50px;
  color: black;
  border: 1px solid #97989a;
}

.otp-popup-grid-box-container-input {
  margin-top: 30px;
  width: 40px;
  padding: 5px;
}

.otp-popup-grid-box {
  width: 430px;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
  align-self: center;
  align-items: center;
}

.otp-popup-parent .otp-popup-grid-box-container {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 10px;
}

.otp-popup-grid-box-container-input {
  margin: 3px;
  width: 50px;
}

.otp-popup-grid-box-container-submit-btn {
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

.insurance-modal-header {
  text-align: end;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.insurance-modal-searchbar {
  width: 100%;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  margin-bottom: 20px;
}

.insurance-modal-background {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(246, 250, 255);
}
/* OTP MODAL CSS START  */

.mainHeading {
  font-size: 1.1em;
  color: rgb(15, 15, 15);
  font-weight: 700;
}

.otpSubheading {
  font-size: 0.9em;
  color: black;
  line-height: 17px;
  text-align: center;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.car-details-otp-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 450px;
  height: 300px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  gap: 20px;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
  border-radius: 15px;
}

.car-details-otp-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 337px;
  width: 588px;
  margin-top: 22px;
  margin-left: 26px;
  box-shadow: inset 2px 4px 4px 4px rgba(0, 0, 0, 0.02);
  background: #f5fbff;
  border-radius: 20px;
}

.car-details-otp-box-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.car-details-otp-box {
  background-color: rgb(228, 228, 228);
  width: 30px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 7px;
  caret-color: rgb(127, 129, 255);
  color: rgb(44, 44, 44);
  outline: none;
  font-weight: 600;
}

.car-details-otp-submit-button-parent {
  margin-top: 20px;
}

.car-details-otp-submit-button {
  padding: 5px 10px;
  border: none;
  background-color: rgb(35 168 250);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  transition-duration: .2s;
}
.car-details-otp-submit-button:hover {
  background-color: rgb(27 141 211);
  transition-duration: .2s;
}
.otp-input {
  background-color: rgb(228, 228, 228);
  width: 30px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 7px;
  caret-color: rgb(35 168 250);
  color: rgb(44, 44, 44);
  outline: none;
  font-weight: 600;
}

.otp-input:focus,
.otp-input:valid {
  background-color: rgba(127, 129, 255, 0.199);
  transition-duration: .3s;
}




.exitBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.171);
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: none;
  color: black;
  font-size: 1.1em;
  cursor: pointer;
}

.resendNote {
  font-size: 0.9em;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.resendBtn {
  background-color: transparent;
  border: none;
  color: rgb(35 168 250);
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 700;
}


/* ............................................................................  */

.vehical_detail_container{
  width: 100%;
  border-top: 1px solid #bbb;
  display: grid;
  /* grid-template-columns: repeat(4,1fr); */
  grid-template-rows: repeat(auto,100px);
  grid-template-columns: repeat(auto-fit, minmax(200px ,1fr));
  gap: 0px 15px; 
  padding: 12px 10px;
  justify-content: center;
  align-content: center;
}

.vehical_detail_content {
  display: flex;
  flex-direction: column;
}
.vehical_detail_content_labels{
  color: #455861;
font-size: 14px;
font-weight: 500;
margin: 6px;
/* border: 1px solid red; */
}
/* .registration_type{
  grid-column: 1/3;
} */



.custom-radio-small-button{
  width: 110px;
height: 50px;
border-radius: 0.9em;
border: 0.981px solid rgba(0, 0, 0, 0.10);
background-color: #fff;
box-shadow: 0px 0px 3.925082206726074px 4px rgba(72, 184, 253, 0.20);
color: #23A8FA;
font-size: 16px;
font-weight: 600;
}
.custom-radio-small-button.active{
  background-color: #23A8FA;
  color: #fff;
}

.custom-radio-button {
  width: 230px;
height: 50px;
  display: flex;
  border-radius: 0.9em;
/* border: 1px solid #23A8FA; */
/* background: #fff; */
/* box-shadow: 0px 0px 2.925082206726074px 4px rgba(72, 184, 253, 0.20); */
  cursor: pointer;
font-size: 16px;
font-weight: 600;
transition: all 0.1s ease-in-out;
color: #23A8FA;
justify-content: center;
align-items: center;
border: none;
}



.custom-radio-button.active {
  background-color: #23A8FA;
  color: #fff;
}

.row_buttons{
  justify-content: space-between; width: 100%; display: flex;
}

.vehical_detail_white_box{

  /* width: 266px; */
  width: 100%;
height: 50px;
border-radius: 0.9em;
border: 1px solid #23A8FA;
background: #fffdfd;
box-shadow: 0px 0px 3.925082206726074px 0px rgba(35, 168, 250, 0.20);
color: #585858;
font-size: 14px;
font-weight: 600;
padding: 5px 10px;
text-align: center;
}
.vehical_detail_white_box:active{
  border-color: #23A8FA;
}

.make-model-search-bar{
  height: 60.27px;
  /* border-radius: 18.307px; */
  border-radius: 0.9em;
border: 1px solid #23A8FA;
height: 50px;
background: #FEFEFE;
box-shadow: 0px 0px 4.576752185821533px 0px rgba(35, 168, 250, 0.20);
text-align: start;
padding: 10px 20px;
color: rgba(0, 0, 0, 0.25);
font-size: 18.168px ;
font-weight: 600;

}


.edit_button {
  height: 50px;
  font-size: 17px;
 padding: 0.5em 2em;
 border: transparent;
 box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
 background: #23A8FA;
 color: white;
 border-radius: 14px;
}
.edit_button:hover {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(30,144,255,1) 0%, rgba(0,212,255,1) 100%);
 }

 .edit_button:active {
 transform: translate(0em, 0.2em);
}
/* ........................................  */

.vehical_detail_model_search_box{
  grid-column: 3 / 5;
}
.registration_type {
  grid-column: 1/3;
}
.previous_policy_type {
  grid-column: 1 / 3;
}

/* ..................................SUBMIT BUTTON.............................................  */


.vehical_details_submit_btn{
  display: flex;
  justify-content: center;
}
.vehical_details_submit_btn button {
  background: #23A8FA;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #2084c2;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
}
.vehical_details_submit_btn button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  right: 0.3em;
  transition: all 0.3s;
}

.vehical_details_submit_btn button:hover .icon {
  width: calc(100% - 0.6em);
}

.vehical_details_submit_btn button .icon svg {
  width: 1.1em;
  transition: all 0.3s;
  color: #23A8FA;
}

.vehical_details_submit_btn button:hover .icon svg {
  transform: translateX(0.1em);
  scale: 2
  ;
}

.vehical_details_submit_btn button:active .icon {
  transform: scale(0.95);
}



.previous_insurer_radio_button{
width: 233px;
display: flex;
justify-content: space-between;
}
.previous_policy_radio_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  border-radius: 0.5rem;
  background-color: #EEE;
}
.previous_insurer_custom_radio_button{
  width: 110px;
height: 50px;
    display: flex;
    border-radius: 0.9em;
  background: #fff;
  box-shadow: 0px 0px 3.925082206726074px 4px rgba(72, 184, 253, 0.20);
    cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.1s ease-in-out;
  color: #23A8FA;
  justify-content: center;
  align-items: center;
}

.previous_insurer_custom_radio_button.active{
  background-color: #23A8FA;
  color: #fff;
}


.car-details-otp-modal-logo {
  width: 200px;
  height: 80px;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 10px;
}

.car-details-otp-modal-logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* OTP MODAL CSS START  */

.mainHeading {
  font-size: 1.1em;
  color: rgb(15, 15, 15);
  font-weight: 700;
}

.otpSubheading {
  font-size: 0.9em;
  color: black;
  line-height: 17px;
  text-align: center;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}


/* ....................................................@media screen {}.....................  */
@media screen and (max-width:885px){
  .vehical_detail_container{
    grid-template-columns: repeat(auto-fit, minmax(219px ,1fr));
  }
  .custom-radio-button{
    width: 197px;
  }
  .previous_insurer_radio_button {
    width: 215px;
  }
  .previous_insurer_custom_radio_button {
    width: 96px;
  }
  .vehical_detail_main_container {
    width: 98%;
  }
  
}



@media screen and (max-width:835px){
  
  .custom-radio-button{
    width: 170px;
  }

  .vehical_detail_container {
    grid-template-columns: repeat(auto-fit, minmax(188px ,1fr));
  }

  .vehical_detail_model_search_box {
    grid-column: 3 / 4;
}
.previous_policy_type {
  grid-column: 1/3;
}
.custom-radio-button {
  width: 225px;
}
.previous_insurer_radio_button{
  width: 200px;
}
.previous_insurer_custom_radio_button {
  width: 95px;
}
.vehical_detail_content_labels {
  font-size: 12px;
}
}

@media screen and (max-width:753px){
  .vehical_detail_model_search_box {
    grid-column: 1 / 3;
}
.previous_policy_type {
  grid-column: 1/3;
}
.custom-radio-button {
  width: 194px;
}
.car-details-otp-modal{
  width: 89vw;
}
}

@media screen and (max-width:476px){
  .vehical_detail_content_labels{

    font-size: 12px;
  }
  .previous_insurer_radio_button{
    width: 200px;
  }
  .previous_insurer_custom_radio_button{
    width: 90px;
  }
  .vehical_detail_model_search_box {
    grid-column: 1 / 3;
}
.previous_policy_type {
  grid-column: 1/3;
}
.custom-radio-button {
  width: 192px;
}
}


@media screen and (max-width:438px){
  .vehical_detail_main_container {
    width: 92%;
  }
  .vehical_detail_model_search_box{
    grid-column: 1 / 2;
  }
  .registration_type {
    grid-column: auto;
  }
  .previous_policy_type {
    grid-column: 1/2;
  }
  .custom-radio-button{
    width: 150px;
  }

  .vehical_detail_container {
    grid-template-columns: repeat(auto-fit, minmax(190px ,1fr));
  }

  .row_buttons {
    width: 55%;
  }
  .custom-radio-small-button{
    width: 88px;
  }
  
}

@media screen and (max-width:350px){
  .custom-radio-button {
    width: 140px;
}
.row_buttons {
  width: 74%;
}
}
@media screen and (max-width:320px){
  .custom-radio-button {
    width: 131px;
}
.row_buttons {
  width: 200px;
}
}