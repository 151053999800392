.registration-number-top-section {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  /* background-color: antiquewhite; */
}
.roadimg {
  position: absolute;
  /* height: 100%; */
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.bg-road-car {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.cargif {
  width: 100%;
  position: relative;
  top: -230px;
}
.registration-number-top-section .back_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}

.registration-number-top-section .back_icon:hover {
  left: 13px;
}
@media screen and (max-width: 768px) {
  .registration-number-top-section {
    height: 445px;
  }
}
@media screen and (max-width: 480px) {
  .registration-number-top-section {
    height: 338px;
  }
}
@media screen and (max-width: 320px) {
  .registration-number-top-section {
    height: 309px;
  }
}
@media screen and (min-width: 2560px) {
  .registration-number-top-section {
    height: 740px;
  }
  .shield-img {
    width: 85%;
  }
}
