
/* CustomCheckbox.css */
 
.custom-checkbox-input:disabled + .custom-checkbox-checkmark {
    /* Add styles for disabled checkboxes */
    background-color: white; /* Change the background color for disabled checkboxes */
    cursor: not-allowed; /* Change the cursor for disabled checkboxes */
  }
 
  .custom-checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
 
  .custom-checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 1.5rem;
    -webkit-user-select: none;
  }
 
  .custom-checkbox-checkmark {
    --clr: #0B6E4F;
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background-color: #ccc;
    border-radius: 50%;
    transition: 300ms;
  }
 
  .custom-checkbox-input:checked ~ .custom-checkbox-checkmark {
    background-color: var(--clr);
    border-radius: 0.5rem;
    animation: pulse 500ms ease-in-out;
  }
 
  .custom-checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
 
  .custom-checkbox-input:checked ~ .custom-checkbox-checkmark:after {
    display: block;
  }
 
  .custom-checkbox-checkmark:after {
    left: 0.5em;
    top: 0.31em;
    width: 0.25em;
    height: 0.5em;
    border: solid #E0E0E2;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
  }
 
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 #0B6E4F90;
      rotate: 20deg;
    }
 
    50% {
      rotate: -20deg;
    }
 
    75% {
      box-shadow: 0 0 0 10px #0B6E4F60;
    }
 
    100% {
      box-shadow: 0 0 0 13px #0B6E4F30;
      rotate: 0;
    }
  }