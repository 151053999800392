

.addition-service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  height: 100%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.326) 0px 1px 4px;
  box-sizing: border-box;
  outline: none;
  position: relative;
}

.addition-service-card.active {
  border: 1px solid #23a8fa;
}

.addition-service-card-img {
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  width: 64.494px;
  height: 64.105px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--primary-color);
  overflow: hidden;
  margin-bottom: 8px;
  padding: 5px;
}
.addition-service-card-img:hover img {
  transform: scale(1.3);
}

.addition-service-card-img img {
  transition: all 0.2s ease-in-out;
  width: 100%;
  object-fit: contain;
  /* filter: hue-rotate(180deg); */
}

.addition-service-card-title {
  position: relative;
  /* font-family: 'Inter'; */
  font-size: 15px;
  line-height: normal;
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
}
/* for travel disable*/
.travel-disabled {
  cursor: not-allowed; /* Disable pointer events */
  opacity: 0.8; /* Dim the card */
  transition: opacity 0.3s ease;
}

.travel-disabled:hover {
  opacity: 0.6; /* Further dim the card on hover */
}

.travel-disabled:hover .coming-soon-overlay {
  display: block; /* Show the "Coming Soon" text */
}

.coming-soon-overlay {
  display: none; /* Hidden by default */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}


.dashboard-card {
  width: 274px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 3.50661px 3.50661px rgba(0, 0, 0, 0.25);
  border-radius: 24.5463px;
  margin-right: 25px;
}

.main-sec-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
}

.records-sub-section {
  height: 230.2213897705078px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  align-items: center;
}

.main-sec-2 {
  height: 100%;
  width: 100%;
  border-radius: 16.140888214111328px;
}

.main-sec-3 {
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  align-items: center;
  align-self: center;
}

.section-1 {
  height: 524px;
  width: 100%;
  border-radius: 17px;
  margin: 38px auto;
  padding: 24px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #0000001a;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #ffffff, #ffffff);
}

.section-2 {
  height: 100%;
  width: 100%;
  border-radius: 16.140888214111328px;
  box-shadow: 0px 3.797856092453003px 3.797856092453003px 0px #0000000d inset;
  border: 0.95px solid #0000001a;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(244, 251, 255, 0.88), rgba(244, 251, 255, 0.88));
  background: #f4fbff 88%;
  display: flex;
}

.section-2-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-flex-end;
  width: 50%;
  padding: 20px;
  height: 100%;
}

.section-2-left-input-div {
  position: relative;
  /* height: 49px; */
  margin-bottom: 24px;
  /* width: 430px; */
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.section-2-form-button {
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form3 {
  background-color: #fff;
  display: block;
  padding: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.form-title {
  font-size: 1.2rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: var(--primary-color);
}

.input-container {
  position: relative;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}

.input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.submit {
  display: block;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.5rem;
  text-transform: uppercase;
  border: none;
  outline: none;
}

.signup-link {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}

.signup-link a {
  text-decoration: underline;
}
.form-sec {
  text-align: center;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.insurance-img {
  width: 50%;
  height: 100%;
  overflow: hidden;
  padding: 20px;
}

.insurance-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.section-2 .row-1 {
  width: 100%;
}

/* .........................OUR PARTNERS CSS...........................  */

.btn-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.btn-section #btn-section {
  border: none;
  outline: none;
  border-radius: 38.6px;
  background: var(--primary-color);
  padding: 6px 20px;
  color: #fff;
  font-size: 22.826px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.testimonials-sec {
  height: 561px;
  width: 100%;
  left: 67px;
  border-radius: 17px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #0000001a;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-left: 53px;
  margin-top: 20px;
}

.testimonails-inner-sec {
  height: 500px;
  left: 116px;
  border-radius: 39px;
  border: 0.95px solid #0000001a;
  box-shadow: 0px 3.797856092453003px 3.797856092453003px 0px #0000000d inset;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(244, 251, 255, 0.88), rgba(244, 251, 255, 0.88)); */
  margin-top: 30px;
}

.testimonails-inner-in-sec {
  /* width: 85%; */
  height: 500px;
  border-radius: 39px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #ffffff, #ffffff); */
  border: 1px solid #0000001a;
  box-shadow: 0px 0px 4px 0px #00000040 inset;
}

.section-4 {
  height: 200px;
}

.dashboard-card-container {
  margin-top: 30px;
  display: flex;
}

.validate {
  position: absolute;
  top: 43px;
  left: 7px;
}

.validate span {
  width: 200px;
}

.dashboard-card-icon {
  position: relative;
  width: 80px;
  height: 64px;
  top: -25px;
  left: 25px;
  border-radius: 24.5463px;
}

.dashboard-card-heading {
  display: flex;
  flex-direction: column;
  text-align: flex-end;
  margin-right: 25px;
  margin-top: -50px;
}

.dashboard-card-footer {
  margin-top: 30px;
  margin-left: 36px;
}

.first-graph-container {
  position: relative;
  width: 395.91px;
  height: 320px;
  left: 25px;
  top: 50px;
  background: #ffffff;
  box-shadow: 0px 4.33876px 4.33876px rgba(0, 0, 0, 0.25);
  border-radius: 23.8632px;
}

.first-graph-container .first-graph {
  width: 100%;
  height: 100%;
}

.second-graph-container {
  position: relative;
  top: 50px;
  left: 50px;
  width: 730px;
  height: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24.5463px;
}

.third-graph-container {
  position: relative;
  top: 90px;
  left: 25px;
  width: 610px;
  height: 347.7px;
  background: #ffffff;
  box-shadow: 0px 6.20899px 6.20899px rgba(0, 0, 0, 0.25);
  border-radius: 38.1019px;
}

.forth-graph-container {
  position: relative;
  top: 90px;
  left: 50px;
  width: 515px;
  height: 347.7px;
  background: #ffffff;
  box-shadow: 0px 6.20899px 6.20899px rgba(0, 0, 0, 0.25);
  border-radius: 38.1019px;
}

.highcharts-data-table table {
  min-width: 320px;
  max-width: 660px;
  margin: 1em auto;
}

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  /* background-color: #fff; */
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  /* box-shadow: 4px 4px 20px #dadada; */
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: white;
  color: #fff;
  height: 190px;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

/* .................... OUR RECORDS CSS......................... */
.records-sec {
  width: 100%;
  height: 100%;
  border-radius: 17px;
  background: white;
  /* border: 1px solid #0000001a;
  box-shadow: 0px 4px 4px 0px #00000040; */
  margin: 40px auto;
  display: flex;
  flex-direction: column;
}

.records-inner-sec {
  width: 100%;
  height: 100%;
  border-radius: 16.140888214111328px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 15px;
}

.records-inner-boxes {
  display: flex;
  width: 252px;
  height: 124.80600000000001px;
  border-radius: 20px;
  border: 0.789px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 3.1542725563049316px 3.1542725563049316px 0px rgba(0, 0, 0, 0.25);
  padding: 6px;
}

.records-inner-boxes-img-div {
  width: 164.485px;
  height: 100%;
  overflow: hidden;
}

.records-inner-boxes-img-div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.records-inner-boxes-text-div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.records-inner-boxes-text-div h4 {
  margin: 0;
  color: #000;
  font-size: 16.437px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.records-inner-boxes-text-div .count-numbers {
  color: #3e8db8;
  font-family: Inter;
  font-size: 21.177px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .................... OUR RECORDS CSS flex-end......................... */
.slide-1 {
  text-align: -webkit-center;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.form-main-section {
  width: 100%;
}

.form-section {
  margin-left: 100px;
  line-height: 28px;
}

.social-links ul {
  list-style: none;
  color: white;
}

.social-links ul li {
  display: inline-block;
}

.social-links ul li a {
  cursor: pointer;
}

/* .................................Partner section CSS Start ..........................  */

.partners-section {
  width: 100%;
  height: 400px;
  border-radius: 17px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #0000001a;
  margin-left: 53px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 50px;
}

.section-3 {
  height: 100%;
  border-radius: 16.140888214111328px;
  background: #f4fbff 88%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(244, 251, 255, 0.88), rgba(244, 251, 255, 0.88));
  border: 0.95px solid #0000001a;
  box-shadow: 0px 3.797856092453003px 3.797856092453003px 0px #0000000d inset;
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.section-3 .slide {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.section-3 .slide .carousel-inner .carousel-item {
  width: 100%;
  height: 100%;
}

.partners-inner-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  column-gap: 20px;
  width: 100%;
  height: 100%;
}

.partners-img-div {
  width: 274.041px;
  height: 119.384px;
  overflow: hidden;
  padding: 11px 19px;
  background-color: white;
  border-radius: 11.986px;
  border: 0.705px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 2.8203001022338867px 2.8203001022338867px 0px rgba(0, 0, 0, 0.25);
}

.partners-img-div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* .................................Partner section CSS flex-end ..........................  */

/* .......................................Help SECTION START............................... */

.help-sec {
  width: 100%;
  height: 286.718px;
  margin: 20px auto;
  border-radius: 17px;
  padding: 26px;
}

.section-4 {
  display: grid;
  grid-template-columns: 2.5fr 5fr 4.5fr;
  height: 100%;
  position: relative;
}

.section-4-img-box {
  width: 243px;
  height: 286.718px;
  overflow: hidden;
}

.section-4-img-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.section-4-inner-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.section-4-question-box {
  align-items: flex-start;
}

.section-4-question-box h3 {
  color: #000;
  font-size: 37.227px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.section-4-question-box p {
  color: #000;
  font-family: Inter;
  font-size: 19.948px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.button-sec {
  width: 313.711px;
  height: 94.892px;
  border-radius: 23.214px;
  border: 0.893px solid #000;
  font-size: 22.171px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.section-4-question-contact-inner-div {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.testimonials .headline {
  background-color: #dadada;
  padding-top: 0.8rem;
}

.headline h2::before {
  content: '';
  width: 40%;
  height: 0.3rem;
  background-color: var(--bg-danger);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.625rem;
  transition: 0.2s ease-in-out;
}
.headline h2:hover:before {
  width: 80%;
}

.contact {
  margin-bottom: 10px;
  padding: 4rem 0;
  background-color: #dcdcdc;
}
.contact .contact-submit-button  {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  background-color: #181717;
  outline: 3px #181717 solid;
  outline-offset: -3px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.contact  .contact-submit-button .text {
  color: white;
  font-weight: 700;
  font-size: 1em;
  transition: 400ms;
}

.contact .contact-submit-button:hover {
  background-color: transparent;
}

.contact .contact-submit-button:hover .text {
  color: #181717;
}

.contact .info li p a {
  font-size: 1.3rem;
  display: inline-block;
  color: var(--text-black);
}
.contact .form-group {
  margin-bottom: 1.5rem;
}
.contact .form-control {
  height: 3.75rem;
  padding: 0.375rem 1.1875rem;
  border: none;
  font-size: 1rem;
  box-shadow: 0 0.1875rem 1.25rem 0 rgb(0 0 0 / 12%);
}
.contact .textarea {
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.75rem 1.1875rem;
  border: none;
  font-size: 1rem;
  margin-top: 0.3125rem;
  box-shadow: 0 0.1875rem 1.25rem 0 rgb(0 0 0 / 12%);
}
.contact .textarea:focus {
  outline: none;
  border: none;
}

.form-group p {
  top: 59px;
}

/* Footer CSS */
.copyright {
  background: var(--bg-danger);
}
.copyright p,
.copyright a {
  color: var(--text-white);
}

.fetching-section {
  /* height: 60vh;
  position: relative;
  background-image: linear-gradient(180deg, rgb(255, 255, 255), rgb(60 177 249 / 55%) 100%); */
}
.fetching-section-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fetching-section .enter-registration-inner-box {
  border: none;
  padding: 10px 20px;
  box-shadow: none;
  height: 230px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.fetching-section .registration-number-top-section-enter-registration-box {
  margin: 0 auto;
  height: unset;
}
.fetching-section .registration-number-top-section-enter-registration-box .shield-img {
  display: none;
}

.fetching-section .enter-registration-inner-box-center {
  margin: 0;
}

.fetching-section .enter-registration-inner-box-center input {
  border-radius: 23.698px;
  background: #fff;
}
.fetching-section .registration-number-top-button {
  height: 50.015px;
  margin-top: 29px;
}
.fetching-section .registration-number-top-button button {
  background-color: #23a8fa;
  color: #fff;
  box-shadow: none;
}
.fetching-section .new-vehical-registration-box span {
  /* border: none; */
  background-color: transparent;
  color: #23a8fa;
  font-size: 13px;
  text-align: center;
  transition: all 0.2s ease-in;
}
.fetching-section .new-vehical-registration-box span:hover {
  background-color: #ffff;
}

.partnerLogo {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin: 0 -35px -20px 0; */
  list-style: none;
  padding: 20px;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
}

.partnerLogo .logo-icon {
  height: 68px;
  min-width: 130px;
  display: inline-block;
  background-color: #fff;
  border: solid 0.6px #dedfdf;
  /* width: 100%; */
  text-align: center;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  background-size: 1232px 918px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  box-shadow: 0px 0px 10px 0px #0d388f69;
}
.partnerLogo .logo-icon:before {
  content: '';
  position: absolute;
  width: 120px;
  height: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-size: 1223px 918px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: top left;
}
.partnerLogo .logo-icon:before {
  background-position: -203px -40px;
  background-image: url('https://static.pbcdn.in/cdn/images/home-v1/all-partner-logo.png?v=2');
}
.partnerLogo .logo-icon.hdfclife:before {
  background-position: -610px -52px;
}

.partnerLogo .logo-icon.bajajgen:before {
  background-position: -204px -122px;
}

.partnerLogo .logo-icon.pnbmetlife:before {
  background-position: -1053px -121px;
}

.partnerLogo .logo-icon.sbilife:before {
  background-position: -486px -121px;
}

.partnerLogo .logo-icon.tataaia:before {
  background-position: -906px -121px;
}

.partnerLogo .logo-icon.bhartiaxaredifining:before {
  background-position: -752px -297px;
}

.partnerLogo .logo-icon.futuregenerali:before {
  background-position: -910px -288px;
}

.partnerLogo .logo-icon.religare:before {
  background-position: -754px -216px;
}

.partnerLogo .logo-icon.star:before {
  background-position: -905px -214px;
}

.partnerLogo .logo-icon.cigna:before {
  background-position: -487px -40px;
}

.partnerLogo .logo-icon.royalsundaram:before {
  background-position: -1052px -288px;
}

.partnerLogo .logo-icon.icicilombard:before {
  background-position: -1050px -610px;
}

.partnerLogo .logo-icon.digit:before {
  background-position: -624px -214px;
}

.partnerLogo .logo-icon.tataaig:before {
  background-position: -878px -54px;
}

.map-section {
  width: 100%;
  height: 190px;
}
/* .......................................Help SECTION flex-end............................... */
/* .......................................media quiries Start.......................................  */

@media screen and (max-width: 1400px) {
  .records-inner-boxes-img-div {
    width: 78px;
  }
}

@media screen and (max-width: 1200px) {
  .section-2-left-input-div {
    width: 100%;
  }

  .section-2-left-input-div label {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .section-2-left-input-div input {
    width: 280px;
    height: 48px;
  }

  .form-sec {
    font-size: 16px;
  }
  .submit {
    width: 164px;
    padding: 16px 11px;
  }

  .section-1 {
    height: 425px;
  }

  .partners-img-div {
    width: 210.041px;
    padding: 8px;
  }

  .records-inner-boxes {
    width: 195px;
  }

  .records-inner-boxes-img-div {
    width: 78px;
  }

  .section-4 {
    grid-template-columns: 0.5fr 5fr 3.5fr;
  }

  .button-sec {
    width: 205.711px;
    height: 94.892px;
    border-radius: 23.214px;
    border: 0.893px solid #000;
    font-size: 18.171px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .section-4-question-box h3 {
    font-size: 29.227px;
  }

  .section-4-question-box p {
    font-size: 15.948px;
  }

  .section-4 {
    column-gap: 15px;
  }

  .section-4-img-box {
    height: 227px;
    width: 127px;
  }
}

/* ...............................  */

@media screen and (max-width: 994px) {
  .main-sec-3 {
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
  }

  .main-sec-2 {
    margin-top: 20px;
  }
  

  .form-sec {
    font-size: 14px;
  }
  .section-2-form-button {
    margin: 10px 0;
  }
  .submit {
    width: 106px;
    padding: 9px 8px;
  }

  .records-inner-boxes {
    width: 210px;
  }

  .fetching-section .enter-registration-inner-box {
    margin-top: 30px;
  }
}

@media screen and (max-width: 827px) {
  .main-sec-3 {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    row-gap: 30px;
    padding: 2px;
  }

  .box {
    margin-bottom: 35px;
  }

  .section-2-left-input-div {
    flex-direction: column;
  }

  .section-2-left-input-div input {
    /* width: 226px; */
    width: 100%;
  }

  .form-sec {
    font-size: 18.815px;
  }

  .section-2-left-form-button {
    margin: 0;
    width: 100%;
  }

  .section-1 {
    height: 460px;
  }

  .section-2-left-form-button button {
    width: 110px;
    height: 31.659px;
    font-size: 9.285px;
  }

  .partners-img-div {
    width: 164.041px;
    height: 92px;
    padding: 5px;
  }

  .partners-section {
    height: 388px;
  }

  /* .records-inner-boxes {
    width: 235.81px;
    height: 97.80600000000001px;
  } */

  .records-inner-boxes-img-div {
    width: 78px;
  }

  .records-inner-boxes-text-div h4 {
    font-size: 18px;
  }

  .section-4-question-box h3 {
    font-size: 27px;
  }
  .provider-img {
    margin-left: 82px;
  }
}

/* .................................  */

@media screen and (max-width: 757px) {
  .form-sec {
    font-size: 14.815px;
  }

  .section-1 {
    height: 425px;
  }

  .partners-section {
    height: 378px;
  }

  .partners-img-div {
    width: 140.041px;
    height: 94px;
    padding: 4px;
  }

  .section-4 {
    grid-template-columns: 4fr 8fr;
  }

  .section-4-img-box {
    height: 230px;
    width: 176px;
  }

  .section-4-question-contact-box {
    grid-column: 1/3;
  }

  .section-4-question-contact-inner-div {
    flex-direction: row;
  }

  .help-sec {
    height: 351.718px;
  }

  .form-sec {
    font-size: 10.815px;
  }

  .form3 {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }

  .submit {
    font-size: 0.7rem;
    width: 84px;
    padding: 9px 2px;
  }
  .provider-img {
    margin-left: 82px;
  }
  .border-2 {
    display: none;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 18px;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-right: 70px;
    margin-top: 22px;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  .gst-img {
    width: 20%;
  }
  .responsive-modal {
    max-width: 88%;
    height: 100%;
  }
  .cross-icon {
    margin-left: 350px;
  }
  .addon-amount {
    margin-left: 40px;
  }
  .additional-amount {
    margin-right: 20px;
  }
  #filter-sec {
  }
  #expiry-date {
    display: none;
  }
  .border-1 {
    display: none;
  }
  .dec {
    border-right: none;
  }
  .idv-sec {
    border: 1px solid #23a8fa;
    padding: 5px;
    border-radius: 4px;
  }
  .addons-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .additional-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .accessories-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .other-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .idv-btn {
    text-align: center;
    margin-left: 140px;
  }
  .dialog-1 {
    width: 100%;
  }
  .addon-btn {
    margin-left: 140px;
  }
  .dialog-2 {
    width: 100%;
  }
  .additional-btn {
    margin-left: 140px;
  }
 
  .accessories-btn {
    margin-left: 140px;
  }
  
  .other-btn {
    margin-left: 140px;
  }
}

@media screen and (max-width: 621px) {
  .partners-img-div {
    width: 113.041px;
    height: 76px;
    padding: 4px;
  }
  #policy-type-sec {
    display: block;
    padding-left: 130px;
  }
  .partners-section {
    height: 310px;
  }

  .records-inner-boxes {
    width: 215px;
    justify-content: space-between;
    height: 101.8px;
  }

  .records-inner-boxes-img-div {
    width: 78px;
  }

  .records-inner-boxes-text-div h4 {
    font-size: 13px;
  }

  .records-inner-boxes-text-div .count-numbers {
    font-size: 20px;
  }

  /* .....................  */

  .section-4-img-box {
    /* grid-column: 1/3; */
    position: absolute;
    right: 10px;
    top: 1px;
  }
  #expiry-date {
    display: none;
  }
  .section-4-question-box {
    grid-column: 1/3;
  }

  .help-sec {
    height: 366.718px;
  }

  .section-4-img-box {
    height: 90px;
    width: 60px;
  }
  .provider-img {
    margin-left: 100px;
  }
  .border-2 {
    display: none;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 18px;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-right: 40px;
    margin-top: 22px;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  .gst-img {
    width: 20%;
  }
  .responsive-modal {
    max-width: 88%;
    height: 100%;
  }
  .cross-icon {
    margin-left: 265px;
  }
  .addon-amount {
    margin-left: 5px;
  }
  .additional-amount {
    margin-right: 20px;
  }
  #filter-sec {
  }
  .border-1 {
    display: none;
  }
  .dec {
    border-right: none;
  }
  #policy-type-sec {
    display: block;
    padding-left: 73px;
    border: none;
  }
  /* .dec-1 {
    padding-left: 130px;
  } */
  .idv-sec {
    border: 1px solid #23a8fa;
    padding: 5px;
    border-radius: 4px;
  }
  .addons-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .additional-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .accessories-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .other-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .idv-btn {
    text-align: center;
    margin-left: 140px;
  }
  .dialog-1 {
    width: 100%;
  }
  .addon-btn {
    margin-left: 140px;
  }
  .dialog-2 {
    width: 100%;
  }
  .additional-btn {
    margin-left: 140px;
  }
  
  .accessories-btn {
    margin-left: 140px;
  }
  .other-btn {
    margin-left: 140px;
  }
}

@media screen and (max-width: 556px) {
  .submit {
    font-size: 1rem;
    width: 171px;
  }

  .form-sec {
    font-size: 20.815px;
    text-align: flex-end;
  }

  .section-2 {
    flex-direction: column;
  }
  #expiry-date {
    display: none;
  }
  .section-2-left {
    align-items: flex-end;
    width: 100%;
  }

  .insurance-img {
    display: none;
  }

  .section-2-left-input-div {
    width: 100%;
  }

  .section-2-left-input-div label {
    font-size: 14px;
  }

  .section-2-left-input-div input {
    height: 38px;
  }

  .section-2-left-form-button {
    width: 300px;
  }

  .section-1 {
    height: 384px;
    padding: 20px;
  }

  .partners-img-div {
    width: 95.041px;
    height: 61px;
    padding: 4px;
  }

  .partners-section {
    height: 277px;
  }

  /* .records-inner-boxes {
    width: 156.81px;
    height: 76.8px;
  } */

  .records-inner-boxes-img-div {
    width: 52px;
  }

  .records-inner-boxes-text-div h4 {
    font-size: 11px;
  }

  .section-4-question-contact-inner-div .button-sec {
    width: 160.711px;
    height: 64.892px;
    font-size: 13.171px;
  }

  .section-4-question-box h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .provider-img {
    margin-left: 85px;
  }
  .border-2 {
    display: none;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 18px;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-right: 40px;
    margin-top: 22px;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  .gst-img {
    width: 20%;
  }
  .responsive-modal {
    max-width: 88%;
    height: 100%;
  }
  .cross-icon {
    margin-left: 230px;
  }
  .addons-amount {
    margin-left: 10px !important;
  }
  .additional-amount {
    margin-right: 20px;
  }
  #policy-type-sec {
    display: block;
    padding-left: 73px;
  }
  .dec-1 {
    padding-left: 130px;
  }
  #filter-sec {
    text-align: center;
  }
  .border-1 {
    display: none;
  }
  .dec {
    border-right: none;
    /* padding-left: 125px; */
  }
  #dec {
    padding-left: 125px;
  }
  #policy-type-sec {
    display: block;
    padding-left: 130px;
  }
  .idv-sec {
    border: 1px solid #23a8fa;
    padding: 5px;
    border-radius: 4px;
  }
  .addons-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .additional-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .accessories-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .other-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .idv-btn {
    text-align: center;
    margin-left: 140px;
  }
  .dialog-1 {
    width: 100%;
  }
  .addon-btn {
    margin-left: 140px;
  }
  .dialog-2 {
    width: 100%;
  }
  .additional-btn {
    margin-left: 140px;
  }
 
  .accessories-btn {
    margin-left: 140px;
  }
 
  .other-btn {
    margin-left: 140px;
  }
}

@media screen and (max-width: 512px) {
  .addition-service-card-title {
    font-size: 14px;
  }
  #expiry-date {
    display: none;
  }
  .tooltip {
    font-size: 11px;
    line-height: 21px;
  }

  .section-2 {
    flex-direction: column;
  }

  .section-2-left {
    align-items: flex-end;
    width: 100%;
  }

  .insurance-img {
    display: none;
  }

  .section-2-left-input-div {
    /* width: 250px; */
    margin-bottom: 12px;
  }

  .section-2-left-input-div label {
    font-size: 14px;
  }

  .section-2-left-input-div input {
    height: 38px;
  }

  .section-2-left-form-button {
    width: 100%;
  }

  .section-1 {
    height: 384px;
    padding: 10px;
  }

  .form-sec {
    text-align: flex-end;
  }

  .section-3 {
    padding: 10px;
  }
  .border-2 {
    display: none;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    font-size: 22px;
  }

  /* ...............Middle Static Header (Our partners)................  */

  .btn-section #btn-section {
    font-size: 16.826px;
  }

  .records-inner-boxes-text-div h4 {
    font-size: 11px;
  }

  .section-4-question-contact-inner-div .button-sec {
    width: 160.711px;
    height: 64.892px;
    font-size: 13.171px;
  }

  .section-4-question-box h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }

  .section-4-question-box p {
    font-size: 11.948px;
  }

  .help-sec {
    height: 284.718px;
  }

  .section-4-img-box {
    height: 65px;
    width: 60px;
  }
  .provider-img {
    margin-left: 55px;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 18px;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-right: 22px;
    margin-top: 19px;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  .gst-img {
    width: 20%;
  }
  .responsive-modal {
    max-width: 88%;
    height: 100%;
  }
  .cross-icon {
    margin-left: 240px;
  }
  .addons-amount {
    margin-left: 10px !important;
  }
  .additional-amount {
    margin-right: 20px;
  }
  .right-side-sec-in-model {
    display: none;
  }
  .dec {
    text-align: center;
    border-right: none;
  }
  #filter-sec {
  }
  .border-1 {
    display: none;
  }
  /* #policy-type-sec {
    display: flex;
  } */
  .idv-sec {
    border: 1px solid #23a8fa;
    padding: 5px;
    border-radius: 4px;
  }
  .addons-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .additional-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .accessories-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .other-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .idv-btn {
    text-align: center;
    margin-left: 140px;
  }
  .dialog-1 {
    width: 100%;
  }
  .addon-btn {
    margin-left: 140px;
  }
  .dialog-2 {
    width: 100%;
  }
  .additional-btn {
    margin-left: 140px;
  }
 
  .accessories-btn {
    margin-left: 140px;
  }

  .other-btn {
    margin-left: 140px;
  }
}

.addition-service-card.disable {
  background-color: #dcdcdc;
  position: relative;
}

.addition-service-card:hover {
  border: 1px solid #23a8fa;
}

/* .addition-service-card.active { 
  border: 2px solid #23A8FA; 
} */

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 23px;
  align-items: center;
  display: flex;
  background-color: #23a8fa;
  color: rgb(110, 105, 105);
  padding: 0px;
  color: white;
  border-radius: 20px 20px 0 0;
  text-align: center;
  display: block;
  opacity: 1;
  z-index: 1;

}

@media screen and (max-width: 428px) {
  .contact .info li p a {
    font-size: 0.8rem;
  }
  #expiry-date {
    display: none;
  }
  #dec {
    padding-left: 40px;
  }
  #policy-type-sec {
    display: block;
    padding-left: 72px;
  }
  .dec-1 {
    padding-left: 75px;
  }
  .tooltip {
    font-size: 9px;
    line-height: 18px;
    height: 17px;
  }

  
  

  .submit {
    font-size: 1rem;
    width: 144px;
  }
  /* ................  */
  .section-2 {
    flex-direction: column;
  }

  .section-2-left {
    align-items: flex-end;
    width: 100%;
    padding: 10px;
  }

  .insurance-img {
    display: none;
  }

  .section-2-left-input-div {
    margin-bottom: 12px;
  }

  .section-2-left-input-div label {
    font-size: 14px;
  }

  .section-2-left-input-div input {
    height: 38px;
  }

  .section-1 {
    height: 384px;
  }

  .form-sec {
    text-align: flex-end;
    font-size: 14px;
  }

  .partners-img-div {
    width: 70.041px;
    height: 48px;
    padding: 2px;
  }

  .partners-section {
    height: 235px;
  }

  .partners-section {
    padding: 10px;
  }

  .records-inner-boxes {
    width: 150px;
  }

  .records-inner-boxes-text-div .count-numbers {
    font-size: 12px;
  }

  .section-4-question-contact-inner-div .button-sec {
    width: 132.711px;
    height: 51.891999999999996px;
    font-size: 11.171px;
  }

  .help-sec {
    height: 256.718px;
  }
  .provider-img {
    margin-left: 22px;
  }
  .border-2 {
    display: none;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    margin-left: 45px;
    margin: auto;
    width: 100%;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  .gst-img {
    width: 20%;
  }
  .right-side-sec-in-model {
    display: none;
  }
  #filter-sec {
  }
  .border-1 {
    display: none;
  }
  .dec {
    border-right: none;
  }
  .idv-sec {
    border: 1px solid #23a8fa;
    padding: 5px;
    border-radius: 4px;
    margin-left: 5px;
  }
  .addons-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .additional-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .accessories-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .other-sec {
    border: 1px solid #23a8fa;
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  .idv-btn {
    text-align: center;
    margin-left: 85px;
  }
  .dialog-1 {
    width: 100%;
  }
  .addon-btn {
    margin-left: 88px;
  }
  .dialog-2 {
    width: 100%;
  }
  .additional-btn {
    margin-left: 87px;
  }

  .accessories-btn {
    margin-left: 88px;
  }
 
  .other-btn {
    margin-left: 88px;
  }
}

@media screen and (max-width: 375px) {
  .registration-number-top-section-enter-registration-box {
    width: 320px;
  }
  .main-sec-3 {
    padding: 0px;
    row-gap: 30px;
    column-gap: 10px;
  }
  #expiry-date {
    display: none;
  }
  .border-1 {
    display: none;
  }
  .submit {
    font-size: 1rem;
    width: 120px;
  }

  .partners-img-div {
    width: 62.041px;
    height: 48px;
    padding: 2px;
  }

  .partners-section {
    height: 235px;
  }

  .section-4-question-box h3 {
    font-size: 19px;
    margin-bottom: 20px;
  }

  .section-4-question-contact-inner-div .button-sec {
    width: 104.711px;
    height: 47.892px;
    font-size: 8.171px;
  }
  .provider-img {
    margin-left: 5px;
  }
  .border-2 {
    display: none;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 18px;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    margin-right: 10px;
    width: 100%;
    margin: auto;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  #dec {
    padding-left: 40px;
  }
  #policy-type-sec {
    display: block;
    padding-left: 48px;
  }
  .dec-1 {
    padding-left: 49px;
  }
  .right-side-sec-in-model {
    display: none;
  }
  #filter-sec {
    display: none;
  }
  .dec {
    border-right: none;
  }
}

@media screen and (max-width: 330px) {
  
  .main-sec-3 {
    padding: 0px;
    row-gap: 12px;
    column-gap: 10px;
  }

  .submit {
    font-size: 0.7rem;
    width: 100px;
  }

  .records-inner-boxes-img-div {
    width: 48px;
  }

  .records-inner-boxes-text-div .count-numbers {
    font-size: 10px;
  }

  .records-inner-boxes-text-div h4 {
    font-size: 9px;
  }

  .records-inner-boxes {
    width: 130px;
  }
  .registration-number-top-section-enter-registration-box {
    width: 299px;
  }
  .box {
    margin-bottom: 9px;
  }

  .fetching-section .enter-registration-inner-box {
    height: 100%;
    margin-top: 12px;
    padding: 15px 9px;
  }
  .enter-registration-inner-box-center input {
    height: 40px;
    font-size: 12px;
  }
  .fetching-section .registration-number-top-button {
    height: 35.015px;
    width: 197px;
    margin: 0 auto;
    margin-top: 13px;
  }
  .provider-img {
    margin-left: 5px;
  }
  .filter-btn {
    width: 75%;
    border: none;
    color: #23a8fa;
    background-color: #ffffff;
    border: 3px solid #23a8fa;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 18px;
  }
  .border-2 {
    display: none;
  }
  .infologo {
    display: none;
  }
  .reverse-img {
    display: none;
  }
  .vectorobject {
    margin-right: 100px;
  }
  .plans-btn {
    background-color: #23a8fa;
    color: #ffffff;
    border: 3px solid #23a8fa;
    width: 75%;
    border: none;
    margin-top: 12px;
    padding: 8px;
    font-size: 22px;
  }
  .right-side-sec-in-model {
    display: none;
  }
  #filter-sec {
  }
  .dec {
    border-right: none;
  }
}
 

.wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.marquee {
  display: flex;
  width: 1200px;
  /* width: 100%; */
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 10%, hsl(0 0% 0% / 1) 90%, hsl(0 0% 0% / 0));
}

.marquee-group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: scrollX 30s linear infinite;
}

@keyframes scrollX {
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.marquee-group2 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: scrollX 30s linear infinite;
  animation-direction: reverse;
  animation-delay: -3s;
}

.image-group {
  /* width: 200px;
  height: 90px;
  border: 2px solid red;
  overflow: hidden; */
  margin: 20px 0;
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  /* height: clamp(8rem,1rem + 30vmin, 20rem); */
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
  margin: 20px 0;
}

.brand-img {
  /* height: 100%;
  width: 100%;
  object-fit: contain; */
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 5px 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  aspect-ratio: 16/9;
}
