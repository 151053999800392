.px_10 {
  padding-left: 10px;
  padding-right: 10px;
}
 
.py_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
 
.mb_25 {
  margin-bottom: 25px;
}
 
.pb_10 {
  padding-bottom: 10px;
}
 
.d_flex {
  display: flex ;
}
.font-12{
  font-size: 12px;
  font-weight: 600;
}
 
.quotation_card_container .quotation_card {
  height: calc(100vh - 153px);
  padding: 5px 10px;
  overflow-y: scroll;
}
 
.health_check_compare ul{
  list-style: none;
}
 
.health_quotation_main_card {
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: relative;
  border-radius:  12px;
  padding: 5px;
  overflow: hidden;
  border: 1px solid #bbb;
}
 
.health_quotation_main_card .logo_container {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  flex-direction: column;
  border-right: 1px dotted #bbb;
}
 
img {
  width: auto;
  max-width: 100%;
}
 
.health_quotation_main_card .quotation_content {
  width: calc(100% - 120px);
  padding-left: 15px;
  padding-right: 5px;
}
 
.personal_accident_page .health_quotation_main_card .quotation_content .content_box_cs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 .quotation_content .content_box_cs .plan_details {
  width: calc(100% / 3);
}
 .quotation_content .content_box_cs .plan_details:nth-child(2) {
  width: calc(100% / 1);
  justify-content: flex-start !important;
}
 .quotation_content .title_pa {
  font-size: 15px;
  font-weight: 600;
  color: #10498a;
}
.quotation_content .suminsured_text {
  color: #03a9f4;
 
}
 
.health_quotation_main_card .quotation_content .suminsured_text {
  font-size: 14px;
  font-weight: 700;
  margin-top: 0px;
}
 
.health_quotation_main_card .quotation_content .content_box_cs .hal_buy_btn_btn.plan_details {
  display: flex;
  justify-content: flex-end;
}
 
 
.health_quotation_main_card .buy_btn {
  background: #03a9f4;
  color: white;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 140px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ddd;
}
.health_quotation_main_card .buy_btn small {
  font-size: 13px;
  margin-bottom: 1px;
  display: block;
  text-align: center;
  line-height: 15px;
}
 
.health_quotation_main_card .buy_btn span {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 20px;
}
 
.buy_btn {
  font-size: 15px ;
}
 
.buy_btn p {
  font-size: 10px;
  margin: 0;
  font-weight: 400;
  line-height: 15px;
}
 
.health_quotation_main_card .quotation_content .content_box_cs .plan_feature.plan_details {
  display: flex;
  justify-content: space-between;
}
 
 
.health_quotation_main_card .quotation_bottom_div .features_btn{
  border: none;
  width: 100%;
  text-align: flex-start;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px;
  margin-top: 5px;
}


/* adddons csss start  */

.addons_flex_container {
  flex-wrap: wrap;
  margin-top: 40px;
}

.addons_flex_container .health_addons_input_box {
  margin-left: 10px;
}
.addons_flex_container .health_addons_input_box label{
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: .5em;
}

.addons_flex_container .health_addons_input_box label span {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 0.5em;
  transition: .25s;
  border: 1px solid black;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
}

.addons_flex_container .health_addons_input_box label input {
    position: absolute;
    left: -9999px;
}



.addons_flex_container .health_addons_input_box label input:checked+span{
  border: 1px solid #23a8fa;
  color: #23a8fa;
}

.addons_flex_container .health_addons_input_box label input:checked+span:before {
box-shadow: inset 0 0 0 .4em #23a8fa;
}

.addons_flex_container .health_addons_input_box label span:before{
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: .375em;
  transition: .25s;
  box-shadow: inset 0 0 0 .125em #8686ef;
}
 
@media (max-width: 991px){
   .health_quotation_main_card .plan_features .features_list li {
    width: calc(100% / 2 - 0px);
}
}
 
@media only screen and (min-width: 320px) and (max-width: 767px){
 
.health_quotation_main_card .quotation_content .content_box_cs .plan_feature.plan_details {
    justify-content: flex-end;
    position: absolute;
    bottom: 6px;
}
 
 
}
 
@media (max-width: 768px) {
  .d_flex.border_bottom.pb_10.quotation_upr_div {
    padding-bottom: 0px !important;
}
.health_quotation_main_card {
  padding: 10px;
}
.health_quotation_main_card .quotation_upr_div {
  flex-direction: column;
  position: relative;
}
 
.health_quotation_main_card .logo_container {
  width: 90px;
  height: 40px;
  padding: 2px;
}
 
.health_quotation_main_card .quotation_content {
  width: 100%;
  padding: 0;
  margin-top: 10px;
}
 
.health_quotation_main_card .buy_btn {
  font-size: 12px;
  width: 120px;
  height: 50px;
}
.health_quotation_main_card .quotation_content .suminsured_text {
  font-weight: 500;
  font-size: 12px;
}
 
.health_quotation_main_card .quotation_upr_div .hal_buy_btn_btn {
  position: absolute;
  top: 0;
  right: 0;
}
 
.buy_btn small {
  font-size: 10px;
  margin-bottom: 1px;
}
 
.health_quotation_main_card .quotation_content .content_box_cs .plan_feature.plan_details {
  display: none;
}
 
 
}
 
 
@media (max-width: 576px){
 
 .health_quotation_main_card .quotation_content .content_box_cs {
    flex-wrap: wrap;
}
 
.health_quotation_main_card .quotation_content .content_box_cs .plan_details {
  width: calc(100% / 1);
  line-height: 22px;
}
 
.buy_btn span {
  line-height: 15px;
}
 
.health_quotation_main_card .quotation_content .content_box_cs .plan_details {
  width: calc(100% / 1);
  line-height: 22px;
}
}