.health_btn {
  align-items: center;
  background: #23a8fa;
  border: none;
  border-radius: 0.9em;
  box-shadow: inset 0 0 1.6em -0.6em #2084c2;
  color: #fff;
  display: inline-flex;
  font-family: inherit;
  font-size: 17px;
  font-weight: 500;
  height: 2.8em;
  letter-spacing: 0.05em;
  overflow: hidden;
  padding: 0.35em 3.3em 0.35em 1.2em;
  position: relative;
}

.health_btn .icon {
  align-items: center;
  background: #fff;
  border-radius: 0.7em;
  display: flex;
  height: 2.2em;
  justify-content: center;
  margin-left: 1em;
  position: absolute;
  right: 0.3em;
  transition: all 0.3s;
  width: 2.2em;
  color: #23a8fa;
}

.health_btn:hover .icon {
  width: calc(100% - 0.6em);
}

.health_btn.back_btn .icon {
  transform: rotate(180deg);
}
