:root {
  --primary-color: #23a8fa;
}

:root {
  /* Background Color */
  --bg-danger: #23a8fa;
  --bg-white: #fff;
  --bg-light-white: #f5f5f5;

  /* Text Color */
  --primary-font: "Poppins", sans-serif;
  --text-white: #fff;
  --text-black: #121212;
  --text-danger: #23a8fa;
  --text-gray: #aaa;
  --text-dark-gray: #777;
}

a,
a:hover {
  text-decoration: none;
}

.kychedding {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  background-color: #31a0d8;
  border-radius: 25px;
  padding: 5px;
  font-size: 22px;
  font-weight: 800;
  font-family: bookman;
  color: beige;
}

.kyclogo {
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 100px;
  margin-top: 180px;
  background-color: #ebf3fa;
  font-family: Palatino;
}

.quotationpage-heading-logo {
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 20px;
  margin-top: 155px;
  background-color: #ebf3fa;
  font-family: Palatino;
}

.inprogress {
  color: #31a0d8;
}

.carnumberela {
  width: 80px;
  height: 110px;
  position: relative;
  bottom: 60px;
}

.datePicker
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #97989a;
}

.datePicker .enginenumber {
  /* margin-bottom: 20px; */
}

.kyclogo > div:nth-child(1) {
  display: flex;
}

.quotationpage-heading-logo > div:nth-child(1) {
  display: flex;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding: 0;
}
/* .................................................vehical_detail START..............................................................  */

.back_arrow_button {
  position: absolute;
  left: 20px;
  cursor: pointer;
}
.vehical_detail_main_container {
  /* width: 1216.87px; */
  max-width: 1000px;
  margin: 20px auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 4.604261875152588px 1.151065468788147px
    rgba(0, 0, 0, 0.25);
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 135px;
}
.vehical_detail_main_container-heading {
  /* width: 299.197px;
height: 56.84px;
border-radius: 11px;
text-align: center;
border: 2.32px solid #23A8FA;
background: #FFF;
color: #23A8FA;
font-weight: 600; */
  font-size: 21.35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  overflow: hidden;
  height: 3rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#212121, #23a8fa),
    linear-gradient(137.48deg, #00d4ff 10%, #79dae0 45%, #edf0f7 67%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: white;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  font-size: 10px;
  letter-spacing: 5px;
  color: #ffffff;
  /* text-shadow: 0 0 4px white; */
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/* ...............................................................  */

.vehical_detail_main_container-contact {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  margin: 20px 0;
}

.vehical_detail_main_container-contact p {
  margin: 0;
  color: #23a8fa;
  font-size: 18.338px;
  font-style: normal;
  font-weight: 500;
}

.address-box {
  margin-right: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 13px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 4px 0.3px rgba(0, 0, 0, 0.25);
  border-radius: 7.534px;
  background: #fff;
}
.vehical_detail_main_container-contact .address {
  width: 100%;
  height: 100%;
}

.vehical_detail_main_container-contact .phone-icon {
  height: 100%;
  border-radius: 7.534px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
  /* background: rgba(255, 255, 255, 0.35);
box-shadow: 0px 0px 4px 0.3px rgba(0, 0, 0, 0.25); */
}

.vehical_detail_main_container-contact .address span {
  color: #23a8fa;
  font-size: 20.338px;
  font-style: normal;
  font-weight: 500;
}

.vehical_detail_main_container-contact .address a {
  text-decoration: none;
  color: #000;
  font-size: 15.32px;
  font-style: normal;
  font-weight: 600;
}

.pagination {
  width: 126.731px;
  height: 30.507px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 4px 0.3px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  color: #23a8fa;
  font-size: 20.338px;
  font-style: normal;
  font-weight: 600;
  margin-right: 25px;
  justify-self: end;
}

.pagination_counter {
  width: 82.414px;
  height: 100%;
  background-color: #20a8ec;
  text-align: center;
  color: #fff;
  -webkit-clip-path: polygon(0 1%, 100% 0%, 70% 100%, 0% 100%);
  clip-path: polygon(0 1%, 100% 0%, 70% 100%, 0% 100%);
}
/* .................................................vehical_detail END..............................................................  */

.vectorobject {
  width: 350px;
  height: 200px;
  position: relative;
  bottom: 140px;
  right: 60px;
}

.infologo {
  padding: 10px;
  margin-left: 50px;
}

.infologo > h6 {
  font-weight: 700;
}

.additional-details-container {
  position: relative;
  margin-bottom: 50px;
  border-radius: 30px;
  border: 1px solid #97989a;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 10px;
}

.additionaldetails {
  background-color: #238fc5;
  border-radius: 25px;
  padding: 5px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 600;
  color: beige;
  margin-bottom: 5px;
  position: absolute;
  top: -15px;
  width: 95%;
  left: 50%;
  transform: translate(-50%);
}

.additional-details-container .gridenginenumber {
  width: 95%;
  margin: 20px auto;
}
/* .additional-details-container .gridenginenumber .css-1fyyp8j-MuiGrid-root{
  padding: 10px !important;
} */
.radiobutton {
  margin-left: 12px;
}
.label_input {
  color: #949699;
  margin-left: 13px;
}

.kyc-proposal-autocomplete-parent .kyc-proposal-autocomplete {
  width: 100%;
  border-radius: 51px;
  background-color: white;
}

.make-model-search-bar-2 {
  width: 76%;
  border-radius: 12px !important;
  height: 50px;
  margin-right: 15px;
}

.make-model-search-bar-2 .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #23a8fa;
}
.kyc-proposal-autocomplete-parent .insurance-autocomplete {
  width: 100%;
  border-radius: 51px;
  background-color: white;
}

.enginenumber {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  font-size: 14px;
  padding: 15px;
  border: 1px solid #97989a;
}

.kyc-proposal-select-martial-status-parent {
  width: 100%;
}

.kyc-proposal-select-martial-status-parent .kyc-proposal-select-martial-status {
  width: 100%;
  height: 50px;
  background-color: white;
  border-radius: 30px;
}

.enginenumber:focus {
  outline: none;
  border: 2px solid #20a8ec;
}

.span_radio {
  font-size: 14px;
  font-weight: 550;
}

.kyc_details {
  text-align: center;
  font-weight: 600;
  margin: 10px;
}

.kyc-consent-div {
  display: flex;
  margin: 10px;
}

.kyc-consent-content {
  margin-top: 8px;
}

.proposal-kyc-main-container {
  position: sticky;
  top: 0;
  border-radius: 30px;
  height: 900px;
}

.kyc_details > div:nth-child(2) {
  color: red;
}

.proposal-kyc-form-container {
  width: 90% !important;
  margin: auto !important;
}

.proposal-kyc-form-container .proposal-kyc-main-container {
  padding-top: 0px;
  padding-left: 0px;
}

.kyc_payment_button {
  display: block;
  width: 18%;
  padding: 10px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: beige;
  background-color: #238fc5;
  border-radius: 15px;
  border: none;
  margin-bottom: 20px;
  font-family: Bradley Hand;
}

.bottomdiv {
  display: flex;
  height: 40px;
  justify-content: space-between;
  text-align: center;
  background-color: #23a8fa;
  border-radius: 25px;
  padding: 5px;
  font-size: 30px;
  font-weight: 700;
  color: beige;
  font-family: Bradley Hand;
}

.carnumberela2 {
  position: relative;
  width: 50px;
  height: 60px;
  left: 20px;
  bottom: 30px;
}

.carnumberela3 {
  position: relative;
  width: 50px;
  height: 60px;
  right: 20px;
  bottom: 30px;
}

.logo_bottom {
  display: block;
  margin: auto;
  width: 20%;
  height: 100px;
  margin-bottom: 30px;
}

.proposal-kyc-vehicleDetail-container-parent {
  border-radius: 30px;
  /* background-color:  #ebf3fa;  */
  border: 1px solid #97989a;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  font-family: Bradley Hand;
  padding: 20px 0 0 0;
  position: relative;
}

.proposal-kyc-vehicleDetail-container-parent .label_input {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.proposal-kyc-premium-container-parent .label_input {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}
.proposal-kyc-vehicle-details {
  /* background-color: #31a0d8; */
  background-color: #238fc5;
  border-radius: 25px;
  padding: 5px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 600;
  color: beige;
  position: absolute;
  top: -15px;
  width: 95%;
  left: 50%;
  transform: translate(-50%);
}

/* .error-validation {
  display: block;
  color: red;
  font-size: 13px;
  margin-top: -18px;
  margin-bottom: 17px;
} */
/* ::-webkit-scrollbar {
  display: block;

  margin-left: 30px;

  float: left;

  height: 300px;

  background: #f5f5f5;

  overflow-y: scroll;

  margin-bottom: 25px;

  border-radius: 20px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;

  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-color: #0ae;

  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
} */
.proposal-kyc-premium-container-parent {
  border-radius: 30px;
  /* background-color:  #ebf3fa;  */
  border: 1px solid #97989a;
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  position: relative;
  padding: 20px 0 0 0;
}

.proposal-kyc-premium-container-parent .proposal-kyc-premium-container {
  padding: 10px;
}

.proposal-kyc-vehicleDetail-container-parent
  .proposal-kyc-vehicleDetail-container {
  margin-top: 0px;
  padding: 10px;
}

@media only screen and (max-width: 1500px) {
  .kyc_payment_button {
    width: 21%;
    font-size: 15px;
    margin-top: 20px;
  }

  .proposal-kyc-main-container {
    position: static;
    height: auto;
  }
}

@media only screen and (max-width: 884px) {
  .vehical_detail_main_container-contact {
    margin: 12px;
  }
  .address-box {
    box-shadow: none;
    margin-right: 0px;
    padding: 9px 5px;
  }
  .vehical_detail_main_container-contact .address {
    font-size: 9px;
    width: 85px;
  }
  .vehical_detail_main_container-contact p {
    font-size: 11px;
  }
  .vehical_detail_main_container-contact .address a {
    font-size: 12px;
  }

  .vehical_detail_main_container-heading {
    width: 11rem;
    height: 3rem;
    font-size: 15px;
  }
  .pagination {
    width: 90px;
    height: 30px;
    margin-right: 0px;
    justify-self: center;
    font-size: 19px;
  }
  .pagination_counter {
    width: 51px;
  }
}

/* .carnumberela,
  .carnumberela2,
  .carnumberela3,
  .vectorobject {
    display: none;
  } */

/* .kyclogo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
  margin-bottom: 40px;
  margin-top: 40px;
} */

.additionaldetails {
  border-radius: 25px;
  padding: 5px;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 600;
  color: beige;
  margin-bottom: 30px;
}

.label_input {
  font-size: 15px;
}

.logo_bottom {
  width: 35%;
  height: 60px;
}

/* .kyc-proposal-autocomplete-parent .kyc-proposal-autocomplete {
  width: 70%;
  border-radius: 51px;
} */

.kyc-proposal-autocomplete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #97989a;
  border-radius: 34px;
  height: 55px;
}

.kyc-proposal-autocomplete-parent .insurance-autocomplete {
  width: 100%;
  border-radius: 51px;
  background-color: white;
}

.proposal-kyc-main-container {
  position: static;
  height: auto;
  margin-bottom: 20px;
}

#navbarScrollingDropdown {
  color: white !important;
}

#navbarScroll {
  margin-right: 40px;
}

#nav-sec {
  background-color: #23a8fa;
  /* height: 100px; */
}

#nav-sec-1 {
  margin-left: 35px;
}

.navbar-toggler {
  margin-left: 250px;
}

#img-sec {
  border-radius: 10px;
}

.drop-down {
  margin-right: 20px;
}

.banner-img {
  width: 600px;
}

/* .btn-section {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 2px;
  color: blueviolet;
  border: none;
  border-radius: 20px;
} */
.sec1 {
  --bs-navbar-padding-y: 0 !important;
}

.main-sec {
  margin-top: 30px;
}

.sec2 .carousel-indicators [data-bs-target] {
  background-color: black !important;
}

.img-sec {
  padding-top: 160px;
  font-size: 35px;
  /* margin-left: 30px; */
}

.img-sec2 {
  margin-top: -153px;
  margin-left: -140px;
}

.quote-sec {
  font-size: 30px;
  margin-left: 50px;
}

.quote-sec-1 {
  font-size: 30px;
  margin-left: 270px;
}

.quote-sec2 {
  font-size: 25px;
  font-weight: bold;
}

.quote-sec3 {
  font-size: 15px;
  font-weight: bold;
}

/* #btn-section {
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  border-radius: 20px;
  font-size: 20px;
} */
HEAD .search {
  margin-left: 45px;
}

.qoute-img {
  margin-left: 20px;
}

.search-opt {
  margin-left: 90px;
  line-height: 35px;
}

.company {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

#brands {
  height: 158.2862548828125px;
  width: 310.7376708984375px;
  left: 132px;
  top: 1175px;
  border-radius: 11.986278533935547px;
  border: 0.71px solid #0000001a;
  box-shadow: 0px 2.820300817489624px 2.820300817489624px 0px #00000040;

  /* padding: 25px;
  border: 0.71px #000000;
  text-align: center; */
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
}

.back_icon {
  cursor: pointer;
}
.form-select {
  border: none;
}

.MuiOutlinedInput-root {
  border: none;
  box-shadow: none;
}

.makeStyles-formControl-1 {
  width: 160px !important;
}

.middle-sec {
  width: 400px;
}

.modal-content {
  /* padding: 45px; */
}

.social {
  background: #6d6d6d;
}

.footer {
  background-color: black;
  color: white;
}

.anchor {
  margin-left: 62px;
}

.anchor-1 {
  margin-left: 102px;
}

.anchor-2 {
  margin-left: 92px;
}

.policy {
  margin-top: 10px;
}

.reg {
  margin-top: 40px;
}

.reg-2 {
  margin-top: 27px;
  margin-left: 37px;
}

.datepicker {
  margin-top: 40px;
}

.radio {
  margin-top: 12px;
}

.plan {
  margin-right: 90px;
}

.apply-btn {
  margin-left: 150px;
}

.apply-btn-1 {
  margin-left: 150px;
  margin-top: 10px;
}

.apply-btn-2 {
  margin-left: 200px;
}

.reset-btn {
  margin-left: 5px;
}

.set-idv {
  margin-left: 20px;
}

.heading {
  /* padding: 33px; */
}

.head ul {
  list-style: none;
}

.service {
  margin-left: 100px;
}

.carousel-indicators {
  margin-left: 10%;
  margin-top: 30px !important;
  display: none;
}

.help-sec p {
  font-size: 23px;
}

.head ul h5 {
  text-decoration: none;
}

.css-bz4dnt-MuiCollapse-root {
  margin-top: "70px";
}

.last-sec {
  height: 3px;
  background-color: rgb(110, 74, 86);
}

.footer-2 {
  background-color: black;
  color: white;
  padding: 10px;
}

/* .css-i4bv87-MuiSvgIcon-root{
  margin-right: 275px;
} */
.footer-3 {
  background-color: black;
  color: white;
  padding: 10px;
}

.final-sec {
  padding: 14px;
  color: white;
}

#brand-1 {
  height: 158.2862548828125px;
  width: 310.7376708984375px;
  left: 132px;
  top: 1175px;
  border-radius: 11.986278533935547px;
  border: 0.71px solid #0000001a;
  box-shadow: 0px 2.820300817489624px 2.820300817489624px 0px #00000040;

  /* padding: 25px;
  border: 0.71px #000000;
  text-align: center; */
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
}

.step-2 {
  margin-left: 80px;
  margin-top: 80px;
}

.car-img {
  margin-left: 700px;
}

.dec {
  border-right: 1px solid;
}

.dec-1 {
  margin-top: 10px;
}

.border-1 {
  border-bottom: 1px rgb(19, 17, 17);
  width: 90%;
  margin-left: 80px;
}

.border-2 {
  border-bottom: 1px solid;
  border-bottom: 1px solid;
}

.qoute {
  margin-left: 50px;
}

.option {
  color: #31a0d8;
}

.date {
  border: none;
  color: blue;
}

.enginenumber-1 {
  width: 85%;
  height: 50px;
  /* border-radius: 30px; */
  font-size: 16px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 1px solid #97989a;
}

/* .MuiInputBase-root {
  border-radius: none;
} */

/* .values{
  margin-left:150px;
  } */

.css-1pnmrwp-MuiTypography-root {
  display: flex;
  flex-direction: row;
}

.reg-date {
  display: flex;
}

.headings {
  margin-top: 20px;
}

.accessories {
  width: 90%;
  margin-left: 10px;
  margin-top: 10px;
  border: 2px solid;
  border-radius: 10px;
  border-radius: 10px;
}

#accessories {
  margin-top: 1px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
  .autocompleteparent
  .autocompletechild {
  margin-left: 20px;
}

.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
  max-width: calc(100% - 6px);
}

/* .css-j204z7-MuiFormControlLabel-root {
  display: flex !important;
  padding: 3px;
} */

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0 !important;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  height: 320px;
}

.idv {
  border: none !important;
  background: white !important;
  color: black !important;
}

#dropdown-basic {
  border: none;
  background: white;
  color: black;
}

.dropdown-menu {
  width: 350px;
  height: 200px;
  border-radius: 20px;
}

.idv-value {
  width: 35%;
  margin-left: 50px;
  border-radius: 10px;
  border: 1px solid;
  padding: 8px;
  font: 17px;
}

.range {
  width: 100%;
  margin: 15px auto;
}

.set-idv {
  margin-left: 30px;
}

.reset-btn {
  border: none;
  color: cadetblue;
  margin-left: 15px;
  margin-top: 15px;
}

.apply-btn {
  margin-left: 260px;
  color: black;
}

.apply-btn-1 {
  margin-left: 250px;
}

.model-sec {
  margin-top: 35px;
}

/* .MuiPaper-root {
  width: 30%;
} */

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  display: none;
}

.bold {
  color: #778c96;
  /* margin-left: 10px; */
}

.error-validation {
  color: red;
  font-size: 11px;
  margin-left: 20px;
}

/* .bold {
  color: #778c96;
  margin-left: 20px;
} */
@media screen and (max-width: 600px) {
  .make-model-search-bar-2 {
    width: 68%;
  }
  .back_arrow_button {
    display: none;
  }
  .banner-img {
    width: 260px;
  }

  .img-sec {
    padding-top: 45px;
    font-size: 20px;
  }

  .contact-img {
    width: 250px;
  }

  .button-sec {
    padding: 10px 10px 10px 10px;
  }

  .button-sec1 {
    padding: 5px 35px 5px 35px;
  }

  .mid-sec {
    padding-left: 80px;
  }

  .ins-img {
    width: 450px;
    padding: 37px;
  }
}
@media only screen and (max-width: 500px) {
  .kychedding {
    margin: 35px;
    text-align: center;
    border-radius: 20px;
    font-size: 20px;
  }
  .kyc-proposal-autocomplete .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 1px solid #97989a;
    border-radius: 12px;
  }
  .kyc_payment_button {
    width: 60%;
    font-size: 13px;
  }

  .carnumberela,
  .carnumberela2,
  .carnumberela3,
  .vectorobject {
    display: none;
  }

  .kyclogo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60px;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .enginenumber {
    /* width: 100%;
    margin: auto;
    height: 56px;
    border-radius: 30px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 15px; */
    width: 100%;
    margin: auto;
    height: 56px;
    border-radius: 12px;
    font-size: 17px;
    padding: 13px;
    margin-bottom: 10px;
  }

  .additionaldetails {
    border-radius: 25px;
    padding: 5px;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 600;
    color: beige;
    margin-bottom: 0px;
  }

  .label_input {
    font-size: 15px;
  }

  .logo_bottom {
    width: 35%;
    height: 60px;
  }

  .infologo {
    padding: 0px;
    margin-left: 0px;
  }

  .kyc-proposal-select-martial-status-parent {
    width: 100%;
    border: 2px solid red;
  }
  .css-1xbvhyl-MuiGrid-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
  .kyc-proposal-autocomplete-parent .insurance-autocomplete {
    width: 100%;
    border-radius: 51px;
    background-color: white;
  }

  .MuiInputBase-root {
    border-radius: 12px !important;
  }

  .proposal-kyc-main-container {
    position: static;
    height: auto;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 437px) {
  .back_arrow_button {
    display: none;
  }
  .address-box {
    box-shadow: none;
    margin-right: 0px;
    padding: 9px 5px;
  }
  .address-box .css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem;
  }
  .vehical_detail_main_container-contact {
    margin: 3px;
    /* grid-template-columns: repeat(2,1fr); */
  }
  .vehical_detail_main_container-contact .address {
    font-size: 9px;
    width: 55px;
  }
  .vehical_detail_main_container-contact p {
    font-size: 9px;
  }
  .vehical_detail_main_container-contact .address a {
    font-size: 9px;
  }

  .vehical_detail_main_container-heading {
    width: 8rem;
    height: 2.2rem;
    font-size: 11px;
  }

  .pagination {
    width: 70px;
    height: 22px;
    margin-right: 0px;
    justify-self: center;
    font-size: 13px;
  }
  .pagination_counter {
    width: 40px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.highcharts-credits {
  display: none;
}

.hidden{
  display: none;
}
